import {CAlert, CButton, CSpinner} from "@coreui/react";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsStock from "highcharts/modules/stock";
import React, {useEffect, useRef, useState} from "react";
import {auditUser, getBrands, getIndexWeights, getPreferences, getTickers} from "../../../api/data/DataProvider";
import MacroReport from "../MacroReport";
import NavBar from "../NavBar";
import {useNavigate} from 'react-router-dom';
import {BRAND_ID, BRAND_NAME, MINI_VIEW, TICKER_NAME} from "./DashboardConstants";
import {
    COMPACT_VIEW_QUERY_LIST,
    DashboardQueryTypes,
    MINI_VIEW_REQUIRED_QUERIES
} from "../../../api/data/DashboardQueryTypes";
import {DashboardReportContextProvider} from "./DashboardReportContextProvider";
import {ViewContextProvider} from "./views/ViewContext";
import {ListsProvider} from "../commonContexts/ListsProvider";
import {CorrelationReportProvider} from "../CorrelationReportContextProvider";
import {AltGtStackContextProvider} from "../../line_chart/alt_gt_stack/AltGtStackContext";
import {DynSWStackProvider} from "../../line_chart/sw_dynamic_stack/DynSWStackContext";
import {monitor} from "../../../event_handler/KeyPressMonitor";
import {TickerWatchListProvider} from "../commonContexts/TickerWatchListProvider";
import {VPTGridProvider} from "../../vpt/VPTGridProvider";
import {ViewsSettingsModal} from "../views-menu/ViewsSettingsModal";
import {RefreshContextProvider} from "../commonContexts/RefreshContextProvider";
import {ProjectionsDataProvider} from "./projectionChartsManagement/ProjectionsDataProvider";
import {DashboardReportViewComponent} from "./DashboardReportViewComponent";

export const macroTickerOption = {
    value: -1,
    label: 'MACRO'
};


export const DASHBOARD_GRID = 'DASHBOARD_GRID';
const DashboardReport = () => {
    monitor.enableHotKeys(true);
    //Logic for fetching and storing the ticker's info:
    let [tickerOptions, setTickerOptions] = useState([]);



    useEffect(() => {
        getTickers().then((res) => {
            let activeTickers = res.data.map(tickResponse => {
                return {
                    value: tickResponse.tickerId,
                    label: tickResponse.tick
                }
            });

            activeTickers.push(macroTickerOption);
            setTickerOptions(activeTickers)
        })
            .catch((err) => {
                console.error("Error fetching Tickers", err)
            })
    }, [])

    const [browserParamsUsed, setBrowserParamsUsed] = useState(false);

    const searchParams = new URLSearchParams(document.location.search)

    const [auth, setAuth] = useState(true);
    const [tick, setTick] = useState({
        name: "Select Ticker",
        id: null
    });
    const [brand, setBrand] = useState({
        name: "Select Brand",
        id: null
    })

    useEffect(() => {
        if (brand.id != null)
            setBrand({
                name: "Select Brand",
                id: null
            })
    }, [tick]);

    const [preferences, setPreferences] = useState(null);
    const [indexWeights, setIndexWeights] = useState(null);
    const [ccIndexWeights, setCcIndexWeights] = useState(null);

    const [showMacroView, setShowMacroView] = useState(false);
    const showMacroViewRef = useRef(false);


    const toggleMiniView = () => {
        setShowMiniView(prev => !prev);
    }

    let navigate = useNavigate();
    const [showMiniView, setShowMiniView] = useState(searchParams.get(MINI_VIEW) === true.toString());
    const [miniViewMode3x3, setMiniViewMode3x3] = useState(true);

    const toggleMiniViewMode = () => {
        if (!showMiniView) setShowMiniView(true);
        setMiniViewMode3x3(prev => !prev);
    }


    useEffect(() => {
        Highcharts.setOptions({
            lang: {
                thousandsSep: ","
            }
        });
    }, []);

    useEffect(() => {
        if (!browserParamsUsed) return;
        const params = new URLSearchParams(window.location.search);

        // Add or update 'ticker' and 'brand' query parameters
        // All checks should validate that id is not null, since we don't want 'Select Brand' to be visible in params.
        if (tick && tick.id && tick.name) params.set(TICKER_NAME, tick.name);
        if (brand && brand.id && brand.name) {
            params.set(BRAND_NAME, brand.name);
        } else {
            params.delete(BRAND_ID);
            params.delete(BRAND_NAME);
        }
        params.set(MINI_VIEW, showMiniView.toString());
        // Update URL with new search parameters
        navigate(window.location.pathname + '?' + params.toString());
    }, [tick, brand, navigate, browserParamsUsed, showMiniView]);

    const [isInvalidTicker, setIsInvalidTicker] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (tickerOptions.length === 0) return;
        let preFetchTickerName = params.get(TICKER_NAME)?.toUpperCase();
        if (preFetchTickerName != null) {
            const currentTicker = tickerOptions.find(ticker => ticker.label === preFetchTickerName);
            if (!currentTicker) {
                setIsInvalidTicker(true);
                return;
            }
            const preFetchBrandName = params.get(BRAND_NAME);
            if (currentTicker && preFetchBrandName != null) {
                getBrands(currentTicker.value).then((res) => {
                    let activeBrand = res.data.find(brand => brand.brandName.toLowerCase() === preFetchBrandName.toLowerCase());
                    setBrand({
                        id: activeBrand.brandId,
                        name: activeBrand.brandName
                    });
                }).catch(err =>
                    console.log("Failed to preload brand for ticker", err)
                )
            }
            setTick({
                id: currentTicker.value,
                name: currentTicker.label
            });
        }
        setBrowserParamsUsed(true);
    }, [tickerOptions]);

    useEffect(() => {
        if (tick.id == null) return;
        getPreferences(tick.name, brand.id)
            .then((res) => {
                setPreferences(res.data);
            })
            .catch((err) => {
                console.log("Got error while fetching preferences: " + err);
            });

        let user = localStorage.getItem('studio_internal_authenticated_user');
        auditUser(user, "DASHBOARD_REPORT", tick.name).then((res) => {
        }).catch((e) => {
        });
    }, [tick, brand]);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.ctrlKey && e.key === 'm') {
                e.preventDefault();
                showMacroViewRef.current = !showMacroViewRef.current
                setShowMacroView(showMacroViewRef.current);
            }

            if (e.ctrlKey && e.key === 's') {
                e.preventDefault();
                setShowMiniView((prev) => !prev);
            }
        };

        document.addEventListener("keydown", handleKeyPress);
        return () => document.removeEventListener("keydown", handleKeyPress);
    }, []);

    /*const toggleIsCCCombineAxis = () => {
        // When turing it on or off, combine:
        if(isCombineCCRevAxis){
            setCombineCCRevAxis(false);
            setShowWeeklyCCSeries(false);
        } else {
            setCombineCCRevAxis(true);
            setShowWeeklyCCSeries(true);
        }
    }*/
    // Ideally, this should all be managed in the DashboardReport context instead of here:
    //Todo: Create a query group and priorities for these as well:
    useEffect(() => {
        if (tick.id == null) return;
        getIndexWeights(tick.name)
            .then((res) => {
                setIndexWeights(res.data);
            })
            .catch((err) => {
                console.log("Got error while fetching index weights: " + err);
                setIndexWeights(null);
            });

    }, [tick]);

    useEffect(() => {
        if (tick.id == null) return;
        getIndexWeights(tick.name, 'YOY_CC')
            .then((res) => {
                setCcIndexWeights(res.data);
            })
            .catch((err) => {
                console.log("Got error while fetching index weights: " + err);
                setCcIndexWeights(null);
            });

    }, [tick]);

    // Update Title
    useEffect(() => {
        let documentTitle = "Oracle - ";
        if (tick.id != null) {
            documentTitle = documentTitle + tick.name;
        }
        if (brand.id != null) {
            documentTitle = documentTitle + " - " + brand.name;
        }

        document.title = documentTitle;
    }, [tick, brand]);

    return (
        <>
            <RefreshContextProvider>
                {isInvalidTicker && <div>
                    <CAlert color="danger">
                        The ticker does not exist. Please validate the ticker name.
                        <br/>
                        <br/>
                        <CSpinner color="light"/>
                    </CAlert>
                </div>}
                {!isInvalidTicker && <div>
                    {auth == null && <CAlert color="info">Trying to authenticate the user, please wait...<br/><CSpinner
                        color="light"/></CAlert>}
                    {auth != null && !auth && <div>
                        <CAlert color="danger">
                            Authentication Failed ! Please check your api key and try again.
                            <br/>
                            <br/>
                            <CButton color="warning" onClick={() => window.location.reload()}>
                                Refresh Now
                            </CButton>
                        </CAlert>
                    </div>
                    }
                    {auth != null && !showMacroView && auth && <div>
                        {HC_exporting(Highcharts)}
                        {require("highcharts/modules/export-data")(Highcharts)}
                        {HighchartsStock(Highcharts)}
                        {tick.id == null && <>
                            <NavBar activeTick={tick}
                                    setActiveTick={setTick}
                                    activeBrand={brand}
                                    setActiveBrand={setBrand}
                                    showMacroView={showMacroView}
                                    setShowMacroView={setShowMacroView}
                                    toggleMiniView={toggleMiniView}
                                    showMiniView={showMiniView}
                                    miniViewMode3x3={miniViewMode3x3}
                                    toggleMiniViewMode={toggleMiniViewMode}
                                    tickerOptions={tickerOptions}
                            />
                            <a id={"HOME"} href={""}> </a>
                            <br/>
                            <br/>
                        </>
                        }


                        {tick.id != null && <div>

                            <ListsProvider activeTick={tick} setActiveTick={setTick}
                                           showMiniView={showMiniView} setShowMiniView={setShowMiniView}>
                                <TickerWatchListProvider activeTick={tick}>
                                    <ViewContextProvider tick={tick} brand={brand}>
                                        <ViewsSettingsModal/>
                                        <VPTGridProvider activeTick={tick}>
                                            <DashboardReportContextProvider
                                                brand={brand}
                                                tick={tick}
                                                customQueryList={showMiniView && miniViewMode3x3 ? COMPACT_VIEW_QUERY_LIST : showMiniView && !miniViewMode3x3 ? MINI_VIEW_REQUIRED_QUERIES : null}
                                                key={`${tick.id}-${brand.id}`}
                                                showCompetitors={true}
                                            >
                                                <ProjectionsDataProvider brand={brand} tick={tick}>

                                                    <CorrelationReportProvider
                                                        tick={tick}
                                                        showMiniView={showMiniView}
                                                    >
                                                        <AltGtStackContextProvider activeTicker={tick.name}
                                                                                   activeBrand={brand}
                                                                                   queryType={DashboardQueryTypes.WEEKLY_DYN_ALT_GT_STACK}>
                                                            <DynSWStackProvider activeTicker={tick}>

                                                                <DashboardReportViewComponent tick={tick}
                                                                                              brand={brand}
                                                                                              setShowMiniView={setShowMiniView}
                                                                                              showMiniView={showMiniView}
                                                                                              miniViewMode3x3={miniViewMode3x3}
                                                                                              setTick={setTick}
                                                                                              setBrand={setBrand}
                                                                                              indexWeights={indexWeights}
                                                                                              ccIndexWeights={ccIndexWeights}
                                                                                              preferences={preferences}
                                                                                              showMacroView={showMacroView}
                                                                                              setShowMacroView={setShowMacroView}
                                                                                              toggleMiniView={toggleMiniView}
                                                                                              toggleMiniViewMode={toggleMiniViewMode}
                                                                                              tickerOptions={tickerOptions}
                                                                />
                                                            </DynSWStackProvider>
                                                        </AltGtStackContextProvider>
                                                    </CorrelationReportProvider>
                                                </ProjectionsDataProvider>
                                            </DashboardReportContextProvider>
                                        </VPTGridProvider>
                                    </ViewContextProvider>
                                </TickerWatchListProvider>
                            </ListsProvider>

                        </div>}
                    </div>}
                    {auth != null && auth && showMacroView && <div>
                        <MacroReport
                            showMacroView={showMacroView}
                            setShowMacroView={setShowMacroView}
                        />
                    </div>}

                </div>}
            </RefreshContextProvider>
        </>
    )
}


export default DashboardReport;

//Todo: Fix the shortcuts to work
// Todo: Move correls report to a dedicated context provider and set it's rendering
//Todo: Remove all Modal based charts, and set them to be normal charts.