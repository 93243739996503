import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import DashboardReport from "./components/oracle/DashboardReport/DashboardReport";
import MacroReport from "./components/oracle/MacroReport";
import {monitor} from "./event_handler/KeyPressMonitor";
import CompositeScreener from "./components/screener/CompositeScreener";
import PortfolioScreenWrapper from "./components/portfolio/common/PortfolioScreenWrapper";
import LinkedinReport from './components/linkedin/LinkedinReports';
import FinDataPage from './components/experimental/FinDataPage';
import SeriesDebuggerPage from './components/experimental/SeriesDebugger';
import LoginPage from "./components/login/LoginPage";
import {useEffect} from "react";
import {
    LINKEDIN_PATH,
    MACRO_PATH,
    PORTFOLIO_PATH,
    SCREENER_PATH,
    EXPERIMENTAL_PATH,
    SERIES_DEBUGGER_PATH,
    CHART_PATH,
    FINDATA_PATH,
    PIPELINE_TRACE_PATH
} from "./components/oracle/PathConstants";
import {SingleChartRenderer} from "./components/oracle/DashboardReport/SingleChartRenderer";
import DataTrace from './components/experimental/DataTrace';


function AuthenticatedRoutes() {
    const user = localStorage.getItem('studio_internal_authenticated_user');

    if (!user) {
        return <Navigate to="/login" />;
    }

    return (
        <Routes>
            <Route path={MACRO_PATH} element={<MacroReport />} />
            <Route path={SCREENER_PATH} element={<CompositeScreener />} />
            <Route path={`${PORTFOLIO_PATH}/*`} element={<PortfolioScreenWrapper />} />
            <Route path={LINKEDIN_PATH} element={<LinkedinReport />} />
            <Route path={CHART_PATH} element={<SingleChartRenderer />} />
            <Route path={FINDATA_PATH} element={<FinDataPage />} />
            <Route path="/" element={<DashboardReport />} />
        </Routes>
    );
}

function App() {
    useEffect(() => {
        // Zoom little out for better viewing
        document.body.style.zoom = "0.97";
        monitor.init();

        // Clarity tracking script only if not on localhost
        if (window.location.hostname !== "localhost") {
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "ms6dx8xtrl");
        }
    }, []);

    return (
        <div className={"App"}>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/*" element={<AuthenticatedRoutes />} />
                    <Route path={EXPERIMENTAL_PATH} element={<FinDataPage />} />
                    <Route path={SERIES_DEBUGGER_PATH} element={<SeriesDebuggerPage />} />
                    <Route path={PIPELINE_TRACE_PATH} element={<DataTrace />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;