import {Button, List, Modal} from "antd";
import {useState} from "react";
import {dollarCellRendererFunction} from "../../../../utils/StudioAgGridUtils";

export const columnDefs = [
    {
        headerName: 'Ticker',
        field: 'tickerName',
        cellStyle: { "vertical-align": "middle" },
        filter: true,
        width: 150,
        sortable: true,
    },
    {
        headerName: 'Wash End Date',
        field: 'washsaleEndDate',
        width: 150,
        filter: true,
        sortable: true,
        sort: 'asc'
    },
    {
        headerName: 'Wash Loss',
        field: 'washsaleLoss',
        width: 200,
        cellStyle: { textAlign: 'right' },
        cellRenderer: dollarCellRendererFunction(),
        filter: true,
        sortable: true
    },
    {
        headerName: 'Wash Trades',
        field: 'washsaleTrades',
        width: 200,
        autoHeight: true,
        cellRenderer: ({ value }) => {
            return <WashTradesModal trades={value}/>
        }
    }
];


const WashTradesModal = (props) => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Button type={"primary"} onClick={() => {setVisible(true)}} size='small'>
                View Trades
            </Button>
        <Modal
            title="Wash Trades"
            visible={visible}
            onCancel={() => {setVisible(false)}}
            footer={null} // Set to null to remove the default footer
        >
            <List
                dataSource={props.trades}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta title={item.tradeDate} description={new Intl.NumberFormat('en-US', { style: 'currency',maximumFractionDigits: 0, currency: 'USD' }).format(item.loss)} />
                    </List.Item>
                )}
            />
        </Modal>
            </>
    );
};
