import {CAlert} from "@coreui/react";
import {Switch} from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {useEffect, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import {getSpreadData} from "../../../api/data/PortfolioDataProvider";
import {debounce} from "../../../utils/StudioChartUtils";

function getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function getColor(seriesName) {
    if (seriesName.startsWith('equal') || seriesName.startsWith('net')) {
        return '#ff00e4';
    } else if (seriesName.startsWith('contribution') || seriesName.startsWith('gross')) {
        return '#636efa';
    } else if (seriesName.startsWith('long')) {
        return '#00ba37';
    } else if (seriesName.startsWith('short')) {
        return '#ef553b';
    } else {
        // return '#00cc96';
        return getRandomColor();
    }
}

const indicesToInclude = ['tlt', 'spy', 'qqq', 'xrt', 'igv', 'xly', 'iwm'];

function SpreadChart(props) {
    const [originalData, setOriginalData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [grossChartData, setGrossChartData] = useState(null);
    const [loading, setLoading] = useState(chartData == null);
    const [isError, setIsError] = useState(false);
    const [rebaseToZero, setRebaseToZero] = useState(true);

    useEffect(() => {
        const abortController = new AbortController();

        setIsError(false)
        setLoading(true)
        getSpreadData(props.accountGroup?.accountIds, indicesToInclude, abortController)
            .then((res) => {
                setOriginalData(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                if (err.code === "ERR_CANCELED") {
                    // do nothing for now
                } else {
                    setIsError(true);
                }
            })


        return () => abortController.abort();
    }, []);

    useEffect(() => {
        if (originalData) {
            setChartData((prev) => {
                return {
                    series: [...calculateSeriesData(originalData)],
                    title: {
                        text: 'Equity Spread'
                    },
                    tooltip: {
                        shared: true,
                        split: true,
                        inactiveOtherSeries: false,
                        xDateFormat: "%A, %e %B, %Y",
                        pointFormatter: function () {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' +
                                Highcharts.numberFormat(this.y * 100, 2) + '%</b><br/>';
                        },
                    },
                    chart: {
                        zoomType: "x",
                        zooming: {
                            mouseWheel: {
                                enabled: false
                            }
                        },
                        height: 600,
                        plotBackgroundColor: '#E5ECF6',
                        panning: {
                            enabled: true,
                            type: 'x'
                        },
                        panKey: 'shift'
                    },
                    legend: {
                        enabled: true,
                        align: "right",
                        verticalAlign: "middle",
                        layout: "vertical",
                        width: 200,
                        itemWidth: 200,
                        itemStyle: {
                            color: "#000000",
                            fontSize: '12px',
                            textOverflow: 'ellipsis'
                        },
                        itemHiddenStyle: {
                            textDecoration: 'none'  // Disable strikethrough effect
                        }
                    },
                    navigation: {
                        buttonOptions: {
                            align: "right",
                            verticalAlign: "top",
                            y: 10
                        }
                    },
                    plotOptions: {
                        marker: {
                            enabled: false
                        }
                    },
                    xAxis: {
                        type: "datetime",
                        labels: {
                            enabled: true
                        },
                        overscroll: '60px',
                        dateTimeLabelFormats: {
                            day: "%Y-%m-%d"
                        },
                        gridLineWidth: 1,
                        gridLineColor: "#fff",
                        events: {
                            afterSetExtremes: debounce((e) => {
                                // Recalculate series here based on available range
                                let chart = e?.target?.chart;
                                if (chart && originalData) {
                                    let startTimestamp = e.min;

                                    let spreadData = rebaseToZero ? calculateSeriesData(originalData, startTimestamp)
                                        : calculateSeriesData(originalData);
                                    if (spreadData) {
                                        for (let series of spreadData) {
                                            let seriesName = series.name;
                                            let seriesData = series.data;
                                            let seriesObject = chart?.series.find(s => s.name === seriesName);
                                            if (seriesObject) {
                                                seriesObject.update({data: seriesData}, true);
                                            }
                                        }
                                    }
                                }
                            }, 100)
                        },
                    },
                    yAxis: [
                        {
                            title: {
                                text: "Return Percent %"
                            },
                            visible: true,
                            opposite: false,
                            gridLineWidth: 1,
                            gridLineColor: "#fff",
                            labels: {
                                formatter: function () {
                                    return (this.value * 100) + '%';
                                }
                            }
                        }
                    ]
                }
            });
            setGrossChartData((prev) => {
                return {
                    series: [...getGrossChartSeriesData(originalData)],
                    title: {
                        text: 'Gross Value'
                    },
                    tooltip: {
                        shared: true,
                        split: true,
                        inactiveOtherSeries: false,
                        xDateFormat: "%A, %e %B, %Y",
                        pointFormatter: function () {
                            let formattedNumber = Highcharts.numberFormat(Math.abs(this.y), 0, '.', ',');
                            let signCheck = this.y < 0 ? '(' : '';
                            let signCheckClose = this.y < 0 ? ')' : '';

                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' +
                                signCheck + '$' + formattedNumber + signCheckClose + '</b><br/>';
                        }
                    },
                    chart: {
                        zoomType: "x",
                        zooming: {
                            mouseWheel: {
                                enabled: false
                            }
                        },
                        height: 600,
                        plotBackgroundColor: '#E5ECF6',
                        panning: {
                            enabled: true,
                            type: 'x'
                        },
                        panKey: 'shift'
                    },
                    legend: {
                        enabled: true,
                        align: "right",
                        verticalAlign: "middle",
                        layout: "vertical",
                        width: 200,
                        itemWidth: 200,
                        itemStyle: {
                            color: "#000000",
                            fontSize: '12px',
                            textOverflow: 'ellipsis'
                        },
                        itemHiddenStyle: {
                            textDecoration: 'none'  // Disable strikethrough effect
                        }
                    },
                    navigation: {
                        buttonOptions: {
                            align: "right",
                            verticalAlign: "top",
                            y: 10
                        }
                    },
                    plotOptions: {
                        marker: {
                            enabled: false
                        }
                    },
                    xAxis: {
                        type: "datetime",
                        labels: {
                            enabled: true
                        },
                        overscroll: '60px',
                        dateTimeLabelFormats: {
                            day: "%Y-%m-%d"
                        },
                        gridLineWidth: 1,
                        gridLineColor: "#fff"
                    },
                    yAxis: [
                        {
                            title: {
                                text: "USD"
                            },
                            visible: true,
                            opposite: false,
                            gridLineWidth: 1,
                            gridLineColor: "#fff",
                            labels: {
                                formatter: function () {
                                    let value = this.value;
                                    let absValue = Math.abs(value);
                                    let formattedNumber = Highcharts.numberFormat(absValue > 1000000 ?
                                        absValue / 1000000 :
                                        absValue / 1000, 0);
                                    let prefix = absValue > 1000000 ? 'M' : 'K';
                                    let sign = value < 0 ? '-' : '';
                                    return sign === '-'
                                        ? '(' + '$' + formattedNumber + prefix + ')'
                                        : '$' + formattedNumber + prefix;
                                }
                            }
                        }
                    ]
                }
            });
        }

    }, [originalData, rebaseToZero]);

    const spreadSeriesToShow = [
        'contributionWeightedSpread',
        'equalWeightedSpread',
        'longStockPerformance',
        'longStockIdioPerformance',
        'shortStockPerformance',
        'shortStockIdioPerformance',
    ];

    const spreadSeriesOrder = [
        ...spreadSeriesToShow,
        ...indicesToInclude,
    ]

    const grossSeriesOrder = [
        'grossValue',
        'netValue',
        'nav',
    ];

    function calculateSeriesData(rawData, startTimestamp) {
        startTimestamp = startTimestamp || -1;
        let seriesArr = [];

        for (const series of Object.values(rawData)) {
            if (series.length !== 0 && spreadSeriesOrder.includes(series[0].source)) {
                series.sort((a, b) => {
                    return a.dataDateMillis - b.dataDateMillis;
                });

                let prevValue = 0.0, currValue = 0.0;
                let newSeriesData = [];
                for (let point of series) {
                    if (point?.dataDateMillis <= startTimestamp) {
                        newSeriesData.push([point?.dataDateMillis, 0.0]);
                    } else {
                        currValue = (1 + prevValue) * point?.value + prevValue;
                        newSeriesData.push([point?.dataDateMillis, currValue]);
                        prevValue = currValue;
                    }
                }

                let newSeries = {
                    data: newSeriesData,
                    name: series[0].source,
                    visible: spreadSeriesToShow.includes(series[0].source),
                    showInLegend: true,
                    color: getColor(series[0].source),
                    lineWidth: 2,
                    dashStyle: series[0].source.includes('Idio') ? 'dash' : 'solid',
                    yAxis: 0
                };

                seriesArr.push(newSeries);
            }
        }

        seriesArr.sort((a, b) => spreadSeriesOrder.indexOf(a.name) - spreadSeriesOrder.indexOf(b.name));
        return seriesArr;
    }

    function getGrossChartSeriesData(rawData) {
        let seriesArr = [];

        for (const series of Object.values(rawData)) {
            if (series.length !== 0 && grossSeriesOrder.includes(series[0].source)) {
                series.sort((a, b) => {
                    return a.dataDateMillis - b.dataDateMillis;
                });

                let newSeriesData = [];
                for (let point of series) {
                    let newPoint = [point?.dataDateMillis, point?.value];
                    newSeriesData.push(newPoint);
                }

                let newSeries = {
                    data: newSeriesData,
                    name: series[0].source,
                    visible: !series[0].source.includes('nav'),
                    showInLegend: true,
                    color: getColor(series[0].source),
                    lineWidth: 2,
                    dashStyle: 'solid',
                    yAxis: 0
                };

                seriesArr.push(newSeries);
            }
        }

        seriesArr.sort((a, b) => grossSeriesOrder.indexOf(a.name) - grossSeriesOrder.indexOf(b.name));
        return seriesArr;
    }

    const onCalcMethodChange = (checked) => {
        setRebaseToZero(checked);
    };

    return (
        <>
            <a id='spread-chart' href={""}> </a>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load chart.
                        Data Not Configured
                    </CAlert>
                </div>}
                {chartData == null && !isError && <p style={{height: "400px", width: "90%"}}> ...</p>}
                {chartData != null && !isError && chartData.series.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available
                    </CAlert>
                </div>}
                {chartData != null && !isError && chartData.series.length !== 0 &&
                    <center>
                        <Switch onChange={onCalcMethodChange} checkedChildren='Recenter to 0'
                                unCheckedChildren='Static calculation' defaultChecked/>
                        <div style={{marginTop: 10}}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'stockChart'}
                                options={chartData}
                            />
                        </div>
                    </center>}
                {grossChartData != null && !isError && grossChartData.series.length !== 0 &&
                    <center>
                        <div style={{marginTop: 10}}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'stockChart'}
                                options={grossChartData}
                            />
                        </div>
                    </center>}
            </LoadingOverlay>

        </>
    )
}

export default SpreadChart;