import {memo, useContext, useEffect, useMemo, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import {CAlert} from "@coreui/react";
import StudioHighChart from "../studio_high_chart/StudioHighChart";
import {DashboardQueryTypes} from "../../api/data/DashboardQueryTypes";
import {SplitContextsConsumerWrapper, ViewContextStore} from "../oracle/DashboardReport/views/ViewContext";
import {getEarningsEventsForTicker} from "../../api/data/DataProvider";
import dayjs from "dayjs";

function getColor(seriesName) {
    if (seriesName.startsWith('idio')) {
        return '#636efa';
    } else if (seriesName.startsWith('factor')) {
        return '#ef553b';
    } else {
        return '#00cc96';
    }
}

export const calculateIdioData = (allData, startTimestamp) => {
    startTimestamp = startTimestamp || -1;

    if (!allData) {
        return null;
    }

    let totalSeries, idioSeries, factorSeries;
    for (const series of allData) {
        if (series.length !== 0) {
            const source = series[0].source;
            series.sort((a, b) => {
                return a.dataDateMillis - b.dataDateMillis;
            });

            if (source === 'idio') {
                idioSeries = series;
            } else if (source === 'factor') {
                factorSeries = series;
            } else if (source === 'total') {
                totalSeries = series;
            }
        }
    }

    // Return back if any of the 3 series are null or empty
    if (!totalSeries || !idioSeries || !factorSeries) {
        return null;
    }

    // Return back if all three series are not of the same length
    if (totalSeries.length !== idioSeries.length || totalSeries.length !== factorSeries.length) {
        return null;
    }

    let totalPrev = 0.0, idioPrev = 0.0, factorPrev = 0.0;
    let totalCurr, idioCurr, factorCurr;
    let newTotalSeries = [], newIdioSeries = [], newFactorSeries = [];
    for (let i = 0; i < totalSeries.length; i++) {
        if (totalSeries[i]?.dataDateMillis <= startTimestamp) {
            newTotalSeries.push([idioSeries[i]?.dataDateMillis, 0]);
            newIdioSeries.push([idioSeries[i]?.dataDateMillis, 0]);
            newFactorSeries.push([idioSeries[i]?.dataDateMillis, 0]);
        } else {
            totalCurr = (1.0 + totalPrev) * totalSeries[i]?.value + totalPrev;
            idioCurr = (1.0 + totalPrev) * idioSeries[i]?.value + idioPrev;
            factorCurr = (1.0 + totalPrev) * factorSeries[i]?.value + factorPrev;

            newTotalSeries.push([totalSeries[i]?.dataDateMillis, totalCurr]);
            newIdioSeries.push([idioSeries[i]?.dataDateMillis, idioCurr]);
            newFactorSeries.push([factorSeries[i]?.dataDateMillis, factorCurr]);

            totalPrev = totalCurr;
            idioPrev = idioCurr;
            factorPrev = factorCurr;
        }
    }

    return {
        idioSeries: newIdioSeries,
        totalSeries: newTotalSeries,
        factorSeries: newFactorSeries
    };
}

const IdioChart = memo(({
                            chartData,
                            loading,
                            isError,
                            activeTicker,
                            activeTickerId,
                            showMiniView,
                            chartToggleConfig,
                            chartHeightOverride,
                            chartWidth,
                            isMiniMode,
                            idioData,
                        }) => {

    let chartHeight = showMiniView ? 450 : 600;
    if (chartHeightOverride != null) chartHeight = Number(chartHeightOverride.replace('px', ''));

    const {modifyViewStateWithObjectForSeriesAndChart} = useContext(ViewContextStore);

    const buildChartData = (newChartData) => {
        if (newChartData == null) return null;
        return {
            series: [...calculateSeriesData(newChartData.data)],
            rangeSelector: {
                // inputEnabled: false,
                buttons: [
                    {
                        type: 'day',
                        count: 1,
                        text: '1d'
                    },
                    {
                        type: 'week',
                        count: 2,
                        text: '1w'
                    },
                    {
                        type: 'week',
                        count: 3,
                        text: '2w'
                    },
                    {
                        type: 'month',
                        count: 1,
                        text: '1m'
                    },
                    {
                        type: 'month',
                        count: 2,
                        text: '2m'
                    },
                    {
                        type: 'month',
                        count: 3,
                        text: '3m'
                    },
                    {
                        type: 'month',
                        count: 6,
                        text: '6m'
                    },
                    {
                        type: 'year',
                        count: 1,
                        text: '1y'
                    },
                    {
                        type: 'year',
                        count: 2,
                        text: '2y'
                    },
                    {
                        type: 'ytd',
                        text: 'YTD'
                    },
                    {
                        type: 'all',
                        text: 'All'
                    }],
                selected: 8
            },
            title: {
                text: activeTicker + ' - IDIO'
            },
            tooltip: {
                shared: true,
                split: true,
                inactiveOtherSeries: false,
                xDateFormat: "%A, %e %B, %Y",
                pointFormat: "{point.y:.5f}"
            },
            chart: {
                zoomType: "x",
                zooming: {
                    mouseWheel: {
                        enabled: false
                    }
                },
                height: chartHeight,
                plotBackgroundColor: '#E5ECF6',
                panning: {
                    enabled: true,
                    type: 'x'
                },
                panKey: 'shift'
            },
            navigation: {
                buttonOptions: {
                    align: "right",
                    verticalAlign: "top",
                    y: 10
                }
            },
            plotOptions: {
                marker: {
                    enabled: false
                }
            },
            xAxis: {
                type: "datetime",
                labels: {
                    enabled: true
                },
                overscroll: 60 * 1000 * 60 * 24 * 16,
                dateTimeLabelFormats: {
                    day: "%Y-%m-%d"
                },
                gridLineWidth: 1,
                gridLineColor: "#fff",
                // plotBands: calculatePlotBands(),
            },
            yAxis: [
                ...['total', 'idio', 'factor'].map(f => {
                        return {
                            title: {
                                text: f
                            },
                            visible: f === 'idio',
                            opposite: false,
                            gridLineWidth: 1,
                            gridLineColor: "#fff",
                            labels: {
                                format: "{value:.2f}"
                            }
                        };
                    }
                )
            ]
        }
    }

    function calculateSeriesData(rawData) {
        let idioObject = calculateIdioData(rawData);
        if (!idioObject) {
            return [];
        }

        let {idioSeries, totalSeries, factorSeries} = idioObject;

        let newSeriesTemplate = {
            visible: true,
            showInLegend: true,
            lineWidth: 2,
            dashStyle: 'solid',
        };

        return [
            {
                ...newSeriesTemplate,
                data: totalSeries,
                name: 'total',
                color: getColor('total'),
                yAxis: 0,
            },
            {
                ...newSeriesTemplate,
                data: idioSeries,
                name: 'idio',
                color: getColor('idio'),
                yAxis: 1,
                // zoneAxis: 'x',
                // zones: calculateZones(getColor('idio')),
            },
            {
                ...newSeriesTemplate,
                data: factorSeries,
                name: 'factor',
                color: getColor('factor'),
                yAxis: 2,
            }
        ];
    }

    const [earningEvents, setEarningEvents] = useState([]);

    const calculatePlotBands = () => {
        let plotBands = [];
        const greenShadeMap = {
            '1Q': 'rgba(144, 238, 144, 0.3)',
            '2Q': 'rgba(144, 238, 144, 0.45)',
            '3Q': 'rgba(144, 238, 144, 0.6)',
            '4Q': 'rgba(144, 238, 144, 0.8)'
        }

        for (const event of earningEvents) {
            let startTime = dayjs(event?.startDate).valueOf();
            let endTime = dayjs(event?.endDate).valueOf();
            let quarterNum = event?.eventName.substring(0, 2);
            plotBands.push({
                from: startTime,
                to: endTime,
                color: greenShadeMap[quarterNum],
                label: {
                    text: quarterNum,
                    verticalAlign: 'bottom',
                    y: -2,
                }
            });
        }
        return plotBands;
    }

    const calculateZones = (defaultSeriesColor) => {
        let zones = [];
        const greenShadeMap = {
            '1Q': 'rgb(0,255,0)',
            '2Q': 'rgb(0,218,0)',
            '3Q': 'rgba(0,184,0,0.6)',
            '4Q': 'rgba(32,112,32,0.8)'
        }

        // Sort the events by start date
        const sortedEvents = earningEvents.sort((a, b) =>
            dayjs(a.startDate).valueOf() - dayjs(b.startDate).valueOf()
        );

        let previousEndTime = null;

        for (const event of sortedEvents) {
            let startTime = dayjs(event?.startDate).valueOf();
            let endTime = dayjs(event?.endDate).valueOf();
            let quarterNum = event?.eventName.substring(0, 2);

            // Add the period before the event with the default series color
            if (previousEndTime !== null && startTime > previousEndTime) {
                zones.push({
                    value: startTime, // This is the start value for the event
                    color: defaultSeriesColor
                });
            }

            // Add the event period with the specific color
            zones.push({
                value: endTime, // This is the end value for the event
                color: greenShadeMap[quarterNum]
            });

            previousEndTime = endTime;
        }

        // Add the remaining period after the last event with the default series color
        if (previousEndTime !== null) {
            zones.push({
                color: defaultSeriesColor
            });
        }

        return zones;
    }

    useEffect(() => {
        getEarningsEventsForTicker(activeTickerId)
            .then((res) => {
                let data = res.data;
                data = data.filter(e => e?.eventType === 'QUARTER_EARNING_PERIOD')
                setEarningEvents(data);
            })
            .catch((err) => {
                console.error("Failed to get earning events!", err);
            })
    }, [activeTickerId]);

    const memoizedChartData = useMemo(() => {
        let idioChart = buildChartData(chartData);
        let chartName = DashboardQueryTypes.IDIO;
        const viewObject = (s) => {
            return {
                yAxisVisible: s === 'idio',
                visible: true
            };
        }
        idioChart?.series.forEach(series => {
            modifyViewStateWithObjectForSeriesAndChart(chartName, series.name, viewObject(series.name));
        })
        return idioChart;
    }, [chartData, activeTicker, earningEvents]);

    return (
        <div>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        height: chartHeight,
                        width: '100%'
                    })
                }}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load - Idio.
                        Data Not Configured
                    </CAlert>
                </div>}
                {memoizedChartData == null && !isError && <div style={{
                    height: chartHeight,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}><h1 style={{color: 'white'}}>Chart is loading. Please wait.</h1></div>}
                {memoizedChartData != null && !isError && memoizedChartData.series.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available - Idio
                    </CAlert>
                </div>}
                {memoizedChartData != null && !isError && memoizedChartData.series.length !== 0 && <center>
                    <div style={{marginTop: 10, paddingLeft: 10}}>
                        <StudioHighChart chartData={memoizedChartData} isMiniView={showMiniView}
                                         queryType={DashboardQueryTypes.IDIO} chartToggleConfig={chartToggleConfig}
                                         chartWidth={chartWidth} isMiniMode={isMiniMode}
                                         chartHeightOverride={chartHeightOverride} idioData={idioData}/>
                    </div>
                </center>}
            </LoadingOverlay>

        </div>
    )
})

export const ApiContextConnectedIdioChart = (props) => {
    return <SplitContextsConsumerWrapper {...props} RawComponent={<IdioChart/>}/>
}

export default IdioChart;