//manages the list of default projections and logic for checking and adding these to the projections lists if required:

import {DashboardQueryTypes} from "../../../../api/data/DashboardQueryTypes";
import {inversePredicate, kpiPredicate} from "../views/ViewContextPredicatesCollection";
import _ from "lodash";

export const DEFAULT_UC_ALGORITHM = 'TWO_THREE_Y_FLAT';

const SeriesForMosaics = ['oracle_index_cc_yoy', 'vela-velorum_total_sales', 'vela-gamma_total_sales', 'orion_total_sales', 'gtrends_us', 'gtrends_ww', 'placer_sales_index'];

const SeriesForGPs = ['oracle_profit_proxy_index', 'vela-velorum_profit_proxy', 'vela-gamma_profit_proxy', 'orion_profit_proxy', 'placer_profit_proxy_index'];

export const DefaultSeriesPairs = [
    [SeriesForMosaics[0], SeriesForGPs[0]],
    [SeriesForMosaics[1], SeriesForGPs[1]],
    [SeriesForMosaics[2], SeriesForGPs[2]],
    [SeriesForMosaics[3], SeriesForGPs[3]],
    [SeriesForMosaics[4], SeriesForMosaics[5]],
    [SeriesForMosaics[6], SeriesForGPs[4]],
];


const MOSAICS = [DashboardQueryTypes.QTR_YOY_LAG0, DashboardQueryTypes.QTR_YOY2_LAG0];

const GP_CHARTS = [DashboardQueryTypes.PROFIT_YOY_LAG0, DashboardQueryTypes.PROFIT_YOY2_LAG0];

let CACHED_DEFAULT_MAP = null;

function addSeriesToProjectionsMap(projectionsMap, seriesList, targetChartsList) {
    seriesList.forEach(series => {
        const item = [];
        targetChartsList.forEach(chart => item.push({queryType: chart, algorithm: DEFAULT_UC_ALGORITHM}));
        projectionsMap[series] = item;
    });
}

 const buildDefaultsMap = () => {
    const map = {};
    addSeriesToProjectionsMap(map, SeriesForMosaics, MOSAICS);
    addSeriesToProjectionsMap(map, SeriesForGPs, GP_CHARTS);
    CACHED_DEFAULT_MAP = map;

};

export const getProjectionsDefaults = () => {
    if(CACHED_DEFAULT_MAP == null) buildDefaultsMap();
    return CACHED_DEFAULT_MAP;
}

export const transformChartMapToProjectionsMap = (chartsProjectionMap) => {
    const transformedAvailableProjections = {};
    for(const chartName in chartsProjectionMap) {
        for(const seriesName in chartsProjectionMap[chartName]){
            if(!transformedAvailableProjections[seriesName]){
                transformedAvailableProjections[seriesName] = [{queryType: chartName, algorithm: DEFAULT_UC_ALGORITHM}];
            } else {
                transformedAvailableProjections[seriesName].push({queryType: chartName, algorithm: DEFAULT_UC_ALGORITHM});
            }
        }
    }
    return transformedAvailableProjections;
}

//Mutates newActiveProjections:
const upsertDefaultProjectionsIfAvailable = (availableProjectionsMap, newActiveProjections) => {
    if(CACHED_DEFAULT_MAP == null) buildDefaultsMap();

    Object.keys(CACHED_DEFAULT_MAP).forEach(seriesName => {
        // Check if in available projections:
        if(availableProjectionsMap[seriesName] != null && availableProjectionsMap[seriesName].length > 0){
            // check if not present in activeProjections:
            if(newActiveProjections[seriesName]  == null || newActiveProjections[seriesName].length === 0){
                newActiveProjections[seriesName] = [];
            }
            // Iterate over each default projection, match if available and add to active projections:
            CACHED_DEFAULT_MAP[seriesName].forEach((proj) => {
                const availableProjection = availableProjectionsMap[seriesName].find(p => p.queryType === proj.queryType && p.algorithm === proj.algorithm);
                const configuredProjection = newActiveProjections[seriesName].find(p => p.queryType === proj.queryType && p.algorithm === proj.algorithm);
                if(availableProjection != null && configuredProjection == null){
                    newActiveProjections[seriesName].push(proj);
                }
            })
        }
    });

}

export const generateNewActiveProjectionsBasedOnAvailableProjections = (availableProjectionsChartMap, oldActiveProjectionsMap) => {
    const transformedAvailableProjections = transformChartMapToProjectionsMap(availableProjectionsChartMap);

    const updatedActiveProjections = {};
    //Compare active projections and remove those which are not available:
    Object.keys(oldActiveProjectionsMap).forEach(seriesName => {
        if(transformedAvailableProjections[seriesName] != null){
            updatedActiveProjections[seriesName] = []; // Initialize to empty array.
            oldActiveProjectionsMap[seriesName].forEach((proj) => {
                //Check if projection exists in the available projections and add:
                if(_.some(transformedAvailableProjections[seriesName], availProj => availProj.queryType === proj.queryType && availProj.algorithm === proj.algorithm)){
                    updatedActiveProjections[seriesName].push(proj);
                }
            })
        }
    });
    upsertDefaultProjectionsIfAvailable(transformedAvailableProjections, updatedActiveProjections);
    return updatedActiveProjections;

}

export const updateProjectionsForQueryType = metadata => {
    const updatedProjections = {};
    metadata.forEach(metadataItem => {
        if(inversePredicate(kpiPredicate)(metadataItem)){
            updatedProjections[metadataItem.seriesName] = [{algorithm: DEFAULT_UC_ALGORITHM}];
        }
    });
    return updatedProjections;
}

export const projectionKeyGenerator = (seriesName, chartName, algo) => seriesName + '_' + chartName + '_' + algo;

export const convertActiveProjectionsToMapOfProjectionsWithKey = (activeProjections) => {
    const projectionMap = {};
    Object.keys(activeProjections).forEach(seriesName => {
        activeProjections[seriesName].forEach(proj => {
            projectionMap[projectionKeyGenerator(seriesName, proj.queryType, proj.algorithm)] = {
                seriesName,
                queryType: proj.queryType,
                algorithm: proj.algorithm,
            };
        })
    });
    return projectionMap;
}

//Todo: Think about the  binding for the 4 charts grid to be like what Utsav posts.
