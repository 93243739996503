export let connectionInfo = {
    // "url": "http://localhost:8086",
    "url": "https://dev-api.devstudioapp.com",
    // "portfolio_service_url": "http://localhost:8905",
    "portfolio_service_url": "https://dev-portfolio.devstudioapp.com",
    // "computation_service_url": "http://localhost:8000",
    "computation_service_url": "https://compute.oracle-analytics.studio",
    // "pipeline_service_url": "http://localhost:8103",
    "pipeline_service_url": "https://pipeline.oracle-analytics.studio",
    "fin_mkt_url": "https://financialmodelingprep.com",
    // "url": "http://64.227.149.2:8086"
    macro_api_url: "https://quickchart.io/chart/render"
}

export let apiKeyInfo = {
    fin_mkt_api_key: "1716594cf1aa20c7f3ff30bddabc3a26"
}
