import _ from "lodash";
import {useEffect, useMemo, useRef, useState} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts/highstock';
import {Checkbox, message, Modal} from "antd";
import {CustomLegend, CustomLegendSmallSizeOptimized} from "./CustomLegend";
import html2canvas from "html2canvas";
import {calculateIdioData} from "../line_chart/IdioChart";
import {debounce} from "../../utils/StudioChartUtils";
import {DashboardQueryTypes} from "../../api/data/DashboardQueryTypes";
import dayjs from "dayjs";

export const transportChartOptionsForSmallViewWithCustomSize = (chartOptions, height, width) => {
    const modifiedOptions = transformChartOptionsForSmallViews(chartOptions);
    return {
        ...modifiedOptions,
        chart: {
            ...modifiedOptions.chart,
            height: Number(height.replace('px', '')),
            width: Number(width.replace('px', ''))
        }
    }
}

export const transformChartOptionsForSmallViews = (chartOptions) => {
    return {
        ...chartOptions,
        chart: {
            ...chartOptions.chart,
            marginTop: 0,
            spacingTop: 0
        },
        title: {
            ...chartOptions.title,
            floating: true,
            margin: 0
        },
        xAxis: {
            ...chartOptions.xAxis,
            offset: 0,
            labels: {
                y: 0,
            }
        },
        navigator: {
            ...chartOptions.navigator,
            height: 15,
            margin: 2
        },
        rangeSelector: {
            ...chartOptions.rangeSelector,
            floating: true,
            buttonPosition: {
                x: 0,
                y: 0
            },
            inputEnabled: false,
            dropdown: 'always'
        }
    }
}

const getChartHeight = (isMiniView, chartHeightOverride) => {
    let chartHeight = isMiniView ? 450 : 600;
    if (chartHeightOverride != null) {
        if (typeof chartHeightOverride === "string") {
            chartHeight = Number(chartHeightOverride.replace('px', ''));
        } else {
            chartHeight = chartHeightOverride;
        }
    }
    return {
        chartHeight: chartHeight,
        chartHeightPx: chartHeight + 'px'
    }
}

const getChartAndLegendWidth = (chartWidth, legendWidthOverRide, isMiniView) => {
    let legendWidth = isMiniView ? 200 : 300;
    let pureChartWidth;
    if (chartWidth != null) {
        const chartWidthNumber = typeof chartWidth === 'string' ? Number(chartWidth.replace('px', '')) : chartWidth;
        legendWidth = chartWidthNumber * 0.15;
        pureChartWidth = chartWidthNumber - legendWidth;
    }
    if (legendWidthOverRide != null) {
        legendWidth = Number(legendWidthOverRide.replace('px', ''));
    }
    return {
        legendWidth,
        pureChartWidth,
        legendWidthPx: legendWidth + 'px'
    }
}

const removeLastDataPointFromSeries = (series) => {
    if (series.length > 0) {
        return series.slice(0, -1);
    }
    return series;
}

const StudioHighChart = ({
                             chartData,
                             chartToggleConfig,
                             queryType,
                             isMiniView,
                             chartHeightOverride,
                             legendWidthOverride,
                             chartWidth,
                             isMiniMode,
                             idioData
                         }) => {

    const {
        viewData = null,
        modifyActiveSeries = null,
        updateChartRange = null,
        setCombineAxes = () => {
        },
        setDynamicLag = () => {
        },
        compactViewModalState,
        compactViewToggleModal,
        dropLastPoint
    } = chartToggleConfig || {};

    //Use chartConfig to setup the active series:
    const [activeSeries, setActiveSeries] = useState([]);
    const [chartOptions, setChartOptions] = useState(chartData);

    const _addGhostForRender = (chartData) => {
        // Find minimum date across all series
        let minDate = _.minBy(_.flatMap(chartData.series, 'data'), 0)[0];
        minDate = dayjs(minDate).subtract(2, 'weeks').valueOf();

        // Find maximum date across all series
        let maxDate = _.maxBy(_.flatMap(chartData.series, 'data'), 0)[0];
        maxDate = dayjs(maxDate).add(2, 'weeks').valueOf();

        // Creating new series
        let newSeries = {
            "showInLegend": false,
            "name": "render_patch_ghost",
            "visible": true,
            "color": "transparent",
            "data": [],
            "enableMouseTracking": false,
            "zoneAxis": null,
            "zones": null,
            "marker": {
            },
            "yAxis": 0,
            "zIndex": null
        };

        // Populating data for new series starting from minDate to maxDate, with an entry for each day
        for (let d = dayjs(minDate); d.valueOf() <= maxDate; d = d.add(1, 'day')) {
            newSeries.data.push([d.valueOf(), 0]);
        }

        // Add the new series to the chartData
        chartData.series.push(newSeries);

        return chartData;
    }

    //Modify the activeSeries array to always have the latest list from the context.
    useEffect(() => {
        if (viewData != null && _.keys(viewData.seriesConfig).length > 0) {
            const newActiveSeries = _.keys(
                _.pickBy(chartData.series, (item) =>
                    (viewData?.seriesConfig[item.name]?.visible) ?? false
                )
            ).map(indexString => Number(indexString));
            setActiveSeries(newActiveSeries);

            let newOptions = {
                ...chartData,
                series: chartData.series.map((seriesItem, index) => ({
                    ...seriesItem,
                    data: dropLastPoint ? removeLastDataPointFromSeries(seriesItem.data) : seriesItem.data,
                    visible: newActiveSeries.includes(index)
                })),
                yAxis: chartData.yAxis.map((series, i) => ({
                    ...series,
                    // TODO [Akshat]: This needs to be improved to use the actual series linked to the axis, rather than relying on matching axis title
                    visible: viewData?.seriesConfig[series?.title?.text]?.yAxisVisible
                }))
            };

            _addGhostForRender(newOptions)

            newOptions = modifyOpacityOfSeries(0, newOptions, false);
            const selectedDynamicLag = viewData?.dynamicLag;
            if (selectedDynamicLag != null && selectedDynamicLag !== 0) {
                const seriesToMove = newOptions.seriesMetadata.filter((obj) => {
                    let tagField = _.get(obj, 'functionalTags', []); // Safely access `functionalTagField` or default to []

                    if (!Array.isArray(tagField)) { // Ignoring items if `functionalTagField` it's not an array
                        return false;
                    }

                    let unwantedTags = ["INTERNAL_REFERENCE", "FINANCIAL", "KPI", "RISK"];
                    return unwantedTags.every(unwantedTag => !tagField.map(tag => tag.toLowerCase()).includes(unwantedTag.toLowerCase())); // It should not include any of the unwanted tags
                }).map(metadataObject => metadataObject.seriesName);
                // since the series might have a dynamic lag already, always use the chartData to get the true series:
                newOptions.series.forEach(series => {
                    if (!seriesToMove.includes(series.name)) return;
                    //Overwrite the old data with a copy from the chartData, since that is source of truth
                    series.data = _.cloneDeep(chartData.series.find(chartDataSeries => series.name === chartDataSeries.name).data);
                    series.data.forEach(dataItem => {
                        let date = new Date(dataItem[0]);
                        date.setDate(date.getDate() + selectedDynamicLag * 7);
                        dataItem[0] = date.getTime();
                    })
                })
            }
            setChartOptions((prev) => {
                let latestChartOptions = {
                    ...newOptions
                };

                ["idio", "total", "factor"].forEach(seriesToCarryOver => {
                    let existingSeries = prev?.series.find(s => s.name === seriesToCarryOver);
                    let newSeries = latestChartOptions?.series.find(s => s.name === seriesToCarryOver);
                    if (existingSeries && newSeries) {
                        newSeries.data = existingSeries.data;
                    }
                });

                return latestChartOptions;
            });

        }
    }, [viewData, JSON.stringify(viewData?.seriesConfig), JSON.stringify(chartData), dropLastPoint]);


    const toggleSeries = (index) => {
        const seriesName = chartData.series[index].name;
        modifyActiveSeries(seriesName);
    }

    const setHoverForSeries = (index, isHovering) => {
        if (!activeSeries.includes(index)) return;
        setChartOptions((prev) => modifyOpacityOfSeries(index, prev, isHovering));
    }


    const modifyOpacityOfSeries = (index, chartOptions, highlightOneSeries) => {
        let newOptions = {
            ...chartOptions
        }
        newOptions.series = newOptions.series.map((seriesItem) => ({
            ...seriesItem,
            opacity: highlightOneSeries ? 0.1 : 1
        }));
        newOptions.series[index].opacity = 1;
        // newOptions.chart.animation = false;
        return newOptions;
    }
    // Calculate the height of the charts and the widths of the legends:

    const {chartHeight, chartHeightPx} = getChartHeight(isMiniView, chartHeightOverride);

    const {
        legendWidth,
        pureChartWidth,
        legendWidthPx
    } = getChartAndLegendWidth(chartWidth, legendWidthOverride, isMiniView);


    const disableRangeSelectorRef = useRef(false);

    const chartRef = useRef(null);
    const rightSideTooltipPositioner = (labelWidth, labelHeight, point) => {
        const chart = chartRef.current?.chart;
        let toolTipX = point.isHeader === true ? point.plotX : point.plotX + 30;
        let toolTipY = point.plotY;
        // Ideally, we would want the tooltips to go as right as they need to go.
        // However, doing that by enabling tooltip.outside leads to floating tooltips, outside the chart area, and hence the compromise.
        if (chart) {
            // Check if tooltip goes beyond the right of the chart
            // If it does, reposition tooltip to the left of the point

            if (chart.plotWidth !== chart.chartWidth) {
                // Axis is enabled, compensate for it:
                let diff = chart.chartWidth - chart.plotWidth;
                toolTipX += diff;
            }

            if (toolTipX + labelWidth > chart.plotWidth) {
                toolTipX = point.plotX - labelWidth;
            }
        }

        return {
            x: toolTipX,
            y: toolTipY
        };
    }

    const isReducedSize = chartWidth < 800 || chartHeight < 450;

    const [localModalState, setLocalModalState] = useState(false);
    const modalState = isMiniMode ? compactViewModalState : localModalState;
    const setModalState = isMiniMode ? compactViewToggleModal : () => setLocalModalState(prev => !prev);

    const memoizedChartOptions = useMemo(() => {
        let newOptions = {
            ...chartOptions,
            plotOptions: {
                area: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                arearange: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                areaspline: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                areasplinerange: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                bar: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                boxplot: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                bubble: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                column: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                columnrange: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                errorbar: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                funnel: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                gauge: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                heatmap: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                line: {
                    animation: false,
                    enableMouseTracking: true,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                pie: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                polygon: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                pyramid: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                scatter: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                series: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}},
                    events: {
                        click: (e) => {
                            if (modifyActiveSeries != null && e.point?.series?.name != null) {
                                modifyActiveSeries(e.point.series.name);
                            }
                        }
                    }
                },
                solidgauge: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                spline: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                treemap: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
                waterfall: {
                    animation: false,
                    enableMouseTracking: false,
                    stickyTracking: true,
                    shadow: false,
                    dataLabels: {style: {textShadow: false}}
                },
            },
            chart: {
                ...chartOptions.chart,
                height: chartHeight,
                width: pureChartWidth != null ? pureChartWidth : undefined,
                reflow: false,
                animation: false,
                events: {
                    click: (e) => {
                        setModalState();
                    }
                }
            },
            legend: {
                ...chartOptions.legend,
                enabled: false
            },
            title: {
                text: chartOptions.title.text
            },
            xAxis: {
                ...chartOptions.xAxis,
                overscroll: 60 * 1000 * 60 * 24 * 14,
                events: {
                    afterSetExtremes: debounce((e) => {
                        // Recalculate idio here based on available range
                        let chart = e?.target?.chart;
                        if (chart && idioData) {
                            let startTimestamp = e.min;

                            let allIdioData = calculateIdioData(idioData, startTimestamp);
                            if (allIdioData) {
                                [["idio", allIdioData.idioSeries],
                                    ["total", allIdioData.totalSeries],
                                    ["factor", allIdioData.factorSeries]
                                ].forEach(seriesDataObj => {
                                    let idioSeriesName = seriesDataObj[0];
                                    let idioSeriesData = seriesDataObj[1];
                                    let idioSeriesObject = chart?.series.find(s => s.name === idioSeriesName);
                                    if (idioSeriesObject) {
                                        idioSeriesObject.update({data: idioSeriesData}, true);
                                        setChartOptions((chartOptions) => {
                                            let newChartOptions = {
                                                ...chartOptions
                                            }
                                            let idioSeriesChartObject = newChartOptions?.series.find(s => s.name === idioSeriesName);
                                            idioSeriesChartObject.data = idioSeriesData;
                                            return newChartOptions;
                                        })
                                    }
                                });
                            }
                        }
                        if (updateChartRange != null) updateChartRange({
                            xMin: e.min,
                            xMax: e.max,
                        });
                        disableRangeSelectorRef.current = true;
                    }, 100)
                },
                crosshair: {
                    color: 'black',
                    dashStyle: 'shortdot'
                }
            },
            navigator: {
                series: {
                    boostThreshold: 1,
                    type: 'line',
                    dataGrouping: {
                        enabled: true,
                        smoothed: true
                    }
                }
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            tooltip: {
                ...chartOptions.tooltip,
                positioner: rightSideTooltipPositioner,
                shadow: false,
                animation: false,
                outside: false,
                backgroundColor: 'rgba(255,255,255,0.7)',
                hideDelay: 0
            },
            rangeSelector: {
                ...chartOptions.rangeSelector,
                selected: (disableRangeSelectorRef.current) ? undefined : (chartOptions.rangeSelector.selected)
            }
        };
        if (viewData?.extremities?.xMin && viewData?.extremities?.xMax) {
            newOptions.xAxis.min = viewData?.extremities.xMin;
            newOptions.xAxis.max = viewData?.extremities.xMax;
            newOptions.rangeSelector.selected = undefined
        }
        const combineChartLevelAxis = viewData?.combineAxes;

        // Combine Axes if enabled
        if (combineChartLevelAxis && newOptions.series.length > 0) {
            if (!newOptions.yAxis.some(yAx => yAx.hasOwnProperty('combinedAxis'))) {
                newOptions.yAxis.forEach(yAx => {
                    if (yAx.hasOwnProperty('originalVisible') === false) {
                        yAx.originalVisible = yAx.visible; // store original yAxis visibility
                    }
                    yAx.visible = false;
                });

                // Add a new axis for the combined setup:
                newOptions.yAxis.push(
                    {
                        "title": {
                            "text": ""
                        },
                        "combinedAxis": true,
                        "visible": true,
                        "opposite": false,
                        "gridLineWidth": 1,
                        "gridLineColor": "#fff",
                        "labels": {
                            "format": "{value:.0f}"
                        }
                    });
            }
            newOptions.series.forEach(series => {
                if (series.hasOwnProperty('originalYAxis') === false) {
                    series.originalYAxis = series.yAxis; // store original yAxis
                }
                // Idio and shortinterest need to be independent
                if (queryType === DashboardQueryTypes.IDIO
                    || (series.name !== 'idio' && !series.name.includes('short_interest'))) {
                    series.yAxis = newOptions.yAxis.length - 1; // Set as the initial and shared y axis
                }
            });
        } else {
            newOptions.series.forEach(series => {
                if (series.hasOwnProperty('originalYAxis')) {
                    series.yAxis = series.originalYAxis; // restore original yAxis if it was saved
                }
            });
            if (newOptions.yAxis.some(yAx => yAx.hasOwnProperty('combinedAxis'))) {
                newOptions.yAxis.pop();
                newOptions.yAxis.forEach(yAx => {
                    if (yAx.hasOwnProperty('originalVisible')) {
                        yAx.visible = yAx.originalVisible; // restore original yAxis if it was saved
                    }
                });
            }
        }

        if (isReducedSize) return transformChartOptionsForSmallViews(newOptions);
        return newOptions;
    }, [chartOptions, chartHeight, viewData?.combineAxes, idioData]);

    const chartContainerRef = useRef();
    const [ssMode, setSSMode] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const copyScreenshot = async () => {
        setSSMode(true);
        const textToCopy = 'Add link and active series lists here.';
        await navigator.clipboard.writeText(textToCopy);
        setTimeout(async () => {
            try {
                // Convert chart to canvas
                const canvas = await html2canvas(chartContainerRef.current);

                // Convert canvas to blob
                canvas.toBlob(async (blob) => {
                    // Attempt to write image and text to the clipboard
                    try {
                        await navigator.clipboard.write([
                            // Add screenshot blob to clipboard items
                            new ClipboardItem({
                                    'image/png': blob
                                }
                            )
                        ]);
                        messageApi.open({
                            content: 'Screenshot copied to clipboard!',
                            type: 'success',
                            duration: 3,

                        });
                    } catch (err) {
                        console.error('Failed to copy image and text to clipboard', err);
                        messageApi.open({
                            content: 'Failed to Generate Screenshot. Ensure you are using Chrome',
                            type: 'error',
                            duration: 5,

                        });
                    }
                });

            } catch (err) {
                console.error('Failed to capture screenshot', err);
                messageApi.open({
                    content: 'Failed to Generate Screenshot. Ensure you are using Chrome',
                    type: 'error',
                    duration: 5,

                });
            } finally {
                setSSMode(false);
            }

        }, 100)

    }

    return (
        <div className="studioChartContainer" style={{height: chartHeight}} ref={chartContainerRef}>
            {contextHolder}
            <div className="chartContainer">
                {!isReducedSize && !isMiniMode && <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    position: 'relative',
                    top: '25px',
                    left: '25px',
                    zIndex: 100
                }}>

                    <Checkbox
                        onChange={(e) => setCombineAxes(e.target.checked)}
                        checked={viewData?.combineAxes}
                    />
                    <span style={{fontSize: '14px', fontWeight: '400', paddingRight: '8px', paddingLeft: '8px'}}>Combine Axes</span>
                    <>
                        <input
                            type="text"
                            style={{width: '50px', height: '18px', alignSelf: 'center', border: '1px solid #ddd'}}
                            onChange={e => {
                                // Ensure the value is a number before setting it
                                const translatedNumber = Number(e.target.value);
                                if (!isNaN(translatedNumber)) {
                                    setDynamicLag(translatedNumber)
                                }
                            }}
                            defaultValue={viewData?.dynamicLag}
                        />
                        <span style={{
                            fontSize: '14px',
                            fontWeight: '400',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                        }}>Dynamic Lag Weeks</span>
                        <a className={'button-factors'} onClick={copyScreenshot}>Copy Screenshot</a>
                    </>
                </div>}
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={memoizedChartOptions}
                    ref={chartRef}
                />

            </div>


            {!isMiniMode && <CustomLegend series={memoizedChartOptions.series}
                                          toggleSeries={toggleSeries}
                                          width={legendWidth}
                                          chartHeight={chartHeightPx}
                                          setHoverForSeries={setHoverForSeries}
                                          ssMode={ssMode}


            />}

            {isMiniMode && <CustomLegendSmallSizeOptimized series={memoizedChartOptions.series}
                                                           toggleSeries={toggleSeries}
                                                           width={legendWidth}
                                                           chartHeight={chartHeightPx}
                                                           setHoverForSeries={setHoverForSeries}
                                                           setCombineAxes={setCombineAxes}
                                                           setDynamicLag={setDynamicLag}
                                                           combineAxes={viewData?.combineAxes}
                                                           dynLag={viewData?.dynamicLag}
                                                           ssMode={ssMode}
                                                           copyScreenshot={copyScreenshot}
            />}

            <Modal width={2500} open={modalState} onOk={setModalState}
                   onCancel={setModalState}>
                <StudioHighChart
                    chartData={chartData} chartToggleConfig={{...chartToggleConfig, compactViewModalState: false}}
                    isMiniView={false} chartHeightOverride={window.innerHeight - 100} legendWidthOverride={'500px'}
                    idioData={idioData} queryType={queryType}/>
            </Modal>

        </div>);
};

//StudioHighChart.whyDidYouRender = true;
export default StudioHighChart;
