import {useContext, useEffect, useState} from "react";
import {ViewContextStore} from "../views/ViewContext";
import {DefaultSeriesPairs} from "./defaultProjectionsUtility";
import {ProjectionsChartGrid} from "./ProjectionsChartGrid";

//Todo: Will have to store the layout for the non-default charts somehow. For now we can rely on default layouts
const generateChartsLayout = (activeProjections) => {
    const chartLayout = [];
    DefaultSeriesPairs.forEach(seriesPair => {
        const chartPair = {
            rhs: null,
            lhs: null
        }
        if(activeProjections[seriesPair[0]] != null){
            chartPair.lhs = {
                seriesName: seriesPair[0],
                projections: activeProjections[seriesPair[0]]
            };
        }
        if(activeProjections[seriesPair[1]] != null){
            chartPair.rhs = {
                seriesName: seriesPair[1],
                projections: activeProjections[seriesPair[1]]
            };
        }
        if(chartPair.rhs != null || chartPair.lhs != null)chartLayout.push(chartPair);
    });
    return chartLayout;
}

//Layout generation code assumes that each series will only have two charts as associated projections:

export const ProjectionsScreen = ({tick, brand}) => {

    const {activeProjections, availableProjectionsUpdated} = useContext(ViewContextStore);

    const [chartLayout, setChartLayout] = useState(generateChartsLayout(activeProjections));

    useEffect(() => {
        //Change the chart layout to reflect the latest chart layout:
        setChartLayout(generateChartsLayout(activeProjections));
    }, [activeProjections]);

    return (
        <>
            {availableProjectionsUpdated &&
                chartLayout.map(chartLayout => chartLayout.rhs != null || chartLayout.lhs != null ?
                    <ProjectionsChartGrid
                        chartLayout={chartLayout}
                        tick={tick}
                        brand={brand}
                        key={'ProjectionsChartLayout' + chartLayout.rhs?.seriesName+chartLayout.lhs?.seriesName}
                    /> : <></>
                )
            }
            {!availableProjectionsUpdated &&
                <h1>Please wait. Available projections have not yet been determined yet.</h1>
            }
        </>
    )
}