import {AgGridReact} from "@ag-grid-community/react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import LoadingOverlay from "react-loading-overlay";
import {getAllPositions, getIntradayPositions} from "../../../api/data/PortfolioDataProvider";
import {columnDefs, intradayColumnDefs} from "./configuration/PositionsOverviewConfiguration";
import {ModuleRegistry} from "@ag-grid-community/core";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {RowGroupingModule} from "@ag-grid-enterprise/row-grouping";

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    RowGroupingModule
]);

function PositionTable(props) {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        marryChildren: true,
        resizable: true,
        sizeColumnsToFit: true,
        skipHeaderOnAutoSize: true,
        suppressMovable: true,
        suppressDragLeaveHidesColumns: true,
    }), []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerName: "Ticker",
            minWidth: 100,
            width: 250,
            cellRendererParams: {
                suppressCount: true,
            },
            sortable: true,
            aggFunc: () => ""
        };
    }, []);

    const extractDataFromResponse = (res) => {
        let data = res.data;
        data.sort((a, b) => b.currentMarketValue - a.currentMarketValue);
        data = data.map(o => {
            return {...o, combinedTicker: o.studioTicker ?? o.bbTicker ?? o.symbol}
        });
        data = data.flatMap(pos => {
            let result = [];
            result.push({...pos, path: [pos.combinedTicker]});

            if (pos.stockPosition) {
                result.push({
                    ...pos.stockPosition,
                    path: [pos.combinedTicker, pos.stockPosition.symbol ?? pos.stockPosition.bbTicker]
                });
            }

            for (let opt of pos.callOptions.concat(pos.putOptions)) {
                result.push({
                    ...opt,
                    path: [pos.combinedTicker, opt.symbol ?? opt.bbTicker]
                });
            }

            if (pos.futurePosition) {
                result.push({
                    ...pos.futurePosition,
                    path: [pos.combinedTicker, pos.futurePosition.symbol ?? pos.futurePosition.bbTicker]
                });
            }

            return result;
        });

        return data;
    }

    const loadData = () => {
        const abortController = new AbortController();
        setLoading(() => true);
        if (Boolean(props?.intraday)) {
            getIntradayPositions(abortController)
                .then((res) => {
                    let data = extractDataFromResponse(res);
                    setRowData(data);
                    setLoading(() => false);
                })
                .catch((err) => {
                    console.error("Failed to get all positions!", err);
                    setLoading(() => false);
                })
        } else {
            getAllPositions(props.reportDate, props.accountGroup?.accountIds, false, abortController)
                .then((res) => {
                    let data = extractDataFromResponse(res);
                    setRowData(data);
                    setLoading(() => false);
                })
                .catch((err) => {
                    console.error("Failed to get all positions!", err);
                    setLoading(() => false);
                })
        }

        return () => abortController.abort();
    }

    useEffect(() => {
        loadData();
    }, [props.reportDate, props.reportTime, props.accountGroup]);

    const getDataPath = useCallback((data) => data.path, []);

    return (
        <div id='position-table' style={{marginTop: 10}}>
            <LoadingOverlay active={loading} text={"Loading..."}>
                <div className="nav-grid ag-theme-alpine" style={{height: '70vh', width: '1120px', margin: '0px auto'}}>
                    <AgGridReact
                        ref={gridRef}
                        grandTotalRow={'top'}
                        columnDefs={Boolean(props?.intraday) ? intradayColumnDefs : columnDefs}
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={false}
                        treeData={true}
                        getDataPath={getDataPath}
                    />
                </div>
            </LoadingOverlay>
        </div>
    )
}

export default PositionTable;