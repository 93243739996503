import {useContext, useEffect, useRef, useState} from "react";
import {getWtdStacks} from "../../../../api/data/DataProvider";
import {RefreshContext} from "../../commonContexts/RefreshContextProvider";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {Modal} from "antd";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import useElementMeasurements from "../../commonHooks/ElementMeasurementsHook";
import Measure from "react-measure";
import {ViewContextStore} from "../views/ViewContext";


export const SECONDMEASURE = 'SECONDMEASURE';
export const ORION = 'ORION';
export const VELA_GAMMA = 'VELA_GAMMA';
export const VELA_VELORUM = 'VELA_VELORUM';
export const ORION_DEBIT = 'ORION_DEBIT';
export const OICC = 'OICC';
const ALL_CC_SERIES = [ORION_DEBIT, VELA_GAMMA, ORION, VELA_VELORUM , SECONDMEASURE];


const fixSizeProps = (options, chartHeight, chartWidth) => ({
    ...options,
    chart: {
        ...options?.chart,
        zooming: {
            mouseWheel: {
                enabled: false
            }
        },
        plotBackgroundColor: '#E5ECF6',
        panning: {
            enabled: true,
            type: 'x'
        },
        panKey: 'shift',
        height: chartHeight,
        width: chartWidth,
    }
})

const getInitialState = () => ({
    data: null,
    loading: false,
    isError: false,
})

const ONE_YEAR = '1';
const TWO_YEAR = '2';


const ESTIMATED_INITIAL_WIDTH = 875;
const ESTIMATED_INITIAL_HEIGHT = 300;
export const WTDStacksComponent = ({tick, brand}) => {

    const {refresh} = useContext(RefreshContext);

    const {wtdStackModalOpen, setWtdStackModalOpen} = useContext(ViewContextStore);

    const [dimensions, handleResize] =
        useElementMeasurements(ESTIMATED_INITIAL_HEIGHT, ESTIMATED_INITIAL_WIDTH);

    const [yearFactor, setYearFactor] = useState(ONE_YEAR);

    const [usedParams, setUsedParams] = useState(null);

    const [dataState, setDataState] = useState({
        [SECONDMEASURE]: getInitialState(),
        [ORION]: getInitialState(),
        [VELA_GAMMA]: getInitialState(),
        [VELA_VELORUM]: getInitialState(),
        [ORION_DEBIT]: getInitialState(),
        [OICC]: getInitialState(),
    });

    const abortControllerRef = useRef(null);

    useEffect(() => {
        // data is same as before, ignore:
        if (usedParams != null && usedParams.tick === tick.id && usedParams.brand === brand.id
            && usedParams.yearFactor === yearFactor) return;

        //do the initial data fetch only when the modal is opened:
        if (abortControllerRef.current) {
            abortControllerRef.current?.abort();
        }
        abortControllerRef.current = new AbortController();
        setUsedParams({
            tick: tick.id,
            brand: brand.id,
            yearFactor: yearFactor,
        })
        ALL_CC_SERIES.forEach((ccSeries) => {
            setDataState(prevState => ({
                ...prevState,
                [ccSeries]: {
                    data: null,
                    loading: true,
                    isError: false,
                }
            }));

            getWtdStacks(tick.id, brand.id, ccSeries, yearFactor, abortControllerRef.current)
                .then((data) => {
                    setDataState(prev => ({
                        ...prev,
                        [ccSeries]: {
                            data: data.data,
                            loading: false,
                            isError: false
                        }
                    }));
                })
                .catch((err) => {
                    console.error(err);
                    setDataState(prev => ({
                        ...prev,
                        [ccSeries]: {
                            data: null,
                            loading: false,
                            isError: true
                        }
                    }))
                })

        });

    }, [ tick, brand, yearFactor, wtdStackModalOpen]);

    useEffect(() => {
        // Unset the used params to null, so that the next time the modal is opened, api call is retriggered.
        setUsedParams(null);
    }, [refresh])

    //Todo: Change this and add error handling:
    const getChartForCCSeries = (ccSeries) => {
        if(dataState[ccSeries].data != null && !dataState[ccSeries].isError && !dataState[ccSeries].loading) return (
        <div key={`ChartGrid-Chart-wtdStack-${ccSeries}`} className="grid-item-wtd-stack">
            <Measure
                bounds
                onResize={handleResize}>
                {({measureRef}) => (
                    <div ref={measureRef}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={fixSizeProps(dataState[ccSeries].data, dimensions.height, dimensions.width)}
                        />
                    </div>
                )}
            </Measure>

        </div>
    );
        if(dataState[ccSeries].isError) {
            return (<h3>{`Errored while loading the WTD chart for ${ccSeries}.`}</h3>);
        }
    }


    const close = () => setWtdStackModalOpen(false);

    return (
        <Modal width={1800} open={wtdStackModalOpen} onOk={close} onCancel={close}>
            <Stack spacing={2} direction={"row"}>
                <Button variant={yearFactor === ONE_YEAR ? 'contained' : 'outlined'}
                        onClick={() => setYearFactor(ONE_YEAR)}>1 Year</Button>
                <Button variant={yearFactor === TWO_YEAR ? 'contained' : 'outlined'}
                        onClick={() => setYearFactor(TWO_YEAR)}>2 Year</Button>
            </Stack>
            <div className="grid-container-wtd-stack">
                {ALL_CC_SERIES.map((ccSeries) => getChartForCCSeries(ccSeries))}
            </div>
        </Modal>
    );


}
