import {AgGridReact} from '@ag-grid-community/react';
import {Alert} from 'antd';
import * as dayjs from 'dayjs';
import {useEffect, useMemo, useRef, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import {getPnlData} from "../../../api/data/PortfolioDataProvider";
import {dollarCellRendererFunction, percentageCellRendererFunction} from "../../../utils/StudioAgGridUtils";

const securityTypeRows = {
    'longStock': 'Long Stock',
    'options': 'Options (Delta Dollars)',
    'totalLong': 'Total Long',
    'hedgesExclCryptoAndRates': 'Hedges (Excl. Crypto & Rates)',
    'shortStock': 'Short Stock',
    'totalShort': 'Total Short',
    'total': 'Total',
};

function getColumnDefs() {
    const commonColumnObject = {
        cellStyle: {textAlign: 'right'},
        width: 150,
        headerClass: 'ag-right-aligned-header',
    };

    return [
        {
            headerName: 'Security Type',
            field: 'securityType',
            cellStyle: {textAlign: 'left'},
            headerClass: 'ag-left-aligned-header',
            width: 290,
        },
        {
            ...commonColumnObject,
            headerName: 'Delta Dollars',
            field: 'deltaDollars',
            cellRenderer: dollarCellRendererFunction(),
        },
        {
            ...commonColumnObject,
            headerName: 'Daily PNL',
            field: 'dailyPnl',
            cellRenderer: dollarCellRendererFunction(),
            width: 120,
        },
        {
            ...commonColumnObject,
            headerName: 'PNL/Value',
            field: 'pnlByValue',
            cellRenderer: percentageCellRendererFunction(2),
            width: 120,
        },
        {
            ...commonColumnObject,
            headerName: 'PNL/Nav',
            field: 'pnlByNav',
            cellRenderer: percentageCellRendererFunction(2),
            width: 120,
        },
    ];
}

function getSummaryColumnDefs() {
    const commonColumnObject = {
        width: 150,
        headerClass: 'ag-left-aligned-header',
    };

    return [
        {
            ...commonColumnObject,
            headerName: 'Total Gross',
            field: 'grossValue',
            cellRenderer: dollarCellRendererFunction(),
        },
        {
            ...commonColumnObject,
            headerName: 'Total Net',
            field: 'netValue',
            cellRenderer: dollarCellRendererFunction(),
        },
        {
            ...commonColumnObject,
            headerName: 'Portfolio Nav',
            field: 'nav',
            cellRenderer: dollarCellRendererFunction(),
        },
        {
            ...commonColumnObject,
            headerName: 'Equal Weighted Spread',
            field: 'equalWeightedSpread',
            cellRenderer: percentageCellRendererFunction(2),
        },
        {
            ...commonColumnObject,
            headerName: 'Contribution Weighted Spread',
            field: 'contributionWeightedSpread',
            cellRenderer: percentageCellRendererFunction(2),
        },
    ];
}

function PnlTable(props) {
    const gridRef = useRef();
    const summaryGridRef = useRef();
    const [loading, setLoading] = useState(false);
    const [columnDefs] = useState(getColumnDefs());
    const [summaryColumnDefs] = useState(getSummaryColumnDefs());
    const [rowData, setRowData] = useState([]);
    const [summaryRowData, setSummaryRowData] = useState([]);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        marryChildren: true,
        resizable: true,
        sizeColumnsToFit: true,
        skipHeaderOnAutoSize: true,
        suppressMovable: true,
        suppressDragLeaveHidesColumns: true,
        menuTabs: [],
    }), []);

    const getPnlRows = (pnlData) => {
        let newRows = [];
        if (pnlData) {
            for (let secType in securityTypeRows) {
                let securityDetailMap = pnlData[secType];
                let currentRow = {
                    securityType: securityTypeRows[secType],
                    deltaDollars: securityDetailMap['deltaDollars'],
                    dailyPnl: securityDetailMap['dailyPnl'],
                    pnlByValue: securityDetailMap['pnlByValue'],
                    pnlByNav: securityDetailMap['pnlByNav'],
                }

                newRows.push(currentRow);
            }
        }

        return newRows;
    }

    const getSummaryPnlRows = (pnlData) => {
        let newRows = [];

        if (pnlData) {
            let currentRow = {
                grossValue: pnlData['grossValue'],
                netValue: pnlData['netValue'],
                nav: pnlData['nav'],
                equalWeightedSpread: pnlData['equalWeightedSpread'],
                contributionWeightedSpread: pnlData['contributionWeightedSpread'],
            }

            newRows.push(currentRow);
        }

        return newRows;
    }

    useEffect(() => {
        const abortController = new AbortController();
        setLoading(true);

        getPnlData(props.reportDate, props.accountGroup?.accountIds, abortController)
            .then((res) => {
                console.log("Response received for pnl data");
                setRowData(getPnlRows(res.data));
                setSummaryRowData(getSummaryPnlRows(res.data));
                setLoading(false);
            })
            .catch((err) => {
                console.error('Error faced while getting pnl data from backend', err);
                setLoading(false);
            })

        return () => abortController.abort();
    }, [props.reportDate, props.reportTime, props.accountGroup]);

    return (
        <div id="pnl-table" style={{marginTop: 10}}>
            {dayjs(props.reportDate).isBefore(dayjs('2024-02-05')) && <Alert
                message="Delta Dollars for Options isn't calculated correctly before 5th Feb, 2024. Please see a later date for accurate calculations"
                type="error"/>}
            <LoadingOverlay active={loading} text={"Loading..."}>
                <div className="nav-grid ag-theme-alpine">
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        animateRows={true}
                        showLoadingOverlay={true}
                        rowSelection='multiple'
                        alwaysShowVerticalScroll={true}
                        debounceVerticalScrollbar={true}
                        suppressColumnVirtualisation={true}
                        domLayout="print"
                    />
                </div>
                <div className="nav-grid ag-theme-alpine">
                    <AgGridReact
                        ref={summaryGridRef}
                        rowData={summaryRowData}
                        columnDefs={summaryColumnDefs}
                        defaultColDef={defaultColDef}
                        animateRows={true}
                        showLoadingOverlay={true}
                        rowSelection='multiple'
                        alwaysShowVerticalScroll={true}
                        debounceVerticalScrollbar={true}
                        suppressColumnVirtualisation={true}
                        domLayout="print"
                    />
                </div>
            </LoadingOverlay>
        </div>
    )
}

export default PnlTable;