import {useContext, useEffect, useState} from "react";
import {
    MiniVPTFinancialSummaryMetrics,
    MiniVPTMainMetrics,
    MiniVPTValuationMetrics,
    TIME_PERIOD_TYPE,
    VPT_AMOUNT_UNITS,
    VPT_METRIC_UNITS,
    VPT_TABLE_MODES,
    VPT_TIME_PERIODS
} from "./VptConfig";

import {RowGroupingModule} from "@ag-grid-enterprise/row-grouping";
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model'
import {AgGridReact} from "@ag-grid-community/react";
import {ModuleRegistry} from "@ag-grid-community/core";
import {Button, Dropdown, Modal, Space} from "antd";
import LoadingOverlay from "react-loading-overlay";
import {DownOutlined} from "@ant-design/icons";
import {VPTGridContext} from "./VPTGridProvider";

ModuleRegistry.registerModules([RowGroupingModule, ClientSideRowModelModule]);

const convertUnitsToWords = (unit) => {
    switch (unit) {
        case 1000:
            return "Thousands";
        case 1000000:
            return "Millions";
        case 1000000000:
            return "Billions";
        default:
            return unit;
    }
};


const getDataPath = data => {
    return data.hierarchy;
}

const GroupCellRenderer = 'agGroupCellRenderer';

export const cellRendererSelector = (params) => {

    if (params.data.hasOwnProperty('children') && params.data.children.length > 0) {
        return {
            component: GroupCellRenderer
        }
    } else {
        return {
            component: DoubleStackRowHeaderRenderer
        }
    }
}

const getRowHeight = (params) => params.data.hasSecondary ? 70 : params.data.hasOwnProperty('isSeparator') ? 60 : 35;

const DoubleStackRowHeaderRenderer = (params) => {
    const hasSecondary = params.data.hasSecondary;
    if (!hasSecondary) {
        // Handle rows which break:
        if (params.data.name != null) return params.data.name;
        else return params.data.name;
    }
    return <>
        <div>{params.data.name}</div>
        <div style={{fontStyle: 'italic'}}>{params.data.secondary?.name}</div>
    </>
}

function convertDateFormat(str) {
    if (str == null) return '';
    const dateParts = str.split("-");
    return `${dateParts[1]}/${dateParts[0]}`;
}

const formatNumber = (num, decimals, addCurrency = false, currencySymbol = '') => {
    const absolute = Math.abs(num);
    const numberWithCommas = absolute.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const formattedNumber = num < 0 ? `(${numberWithCommas})` : numberWithCommas;
    return addCurrency ? `${currencySymbol}${formattedNumber}` : formattedNumber;
};

export const VPTGrid = ({onlyGridView = false, gridHeight, gridWidth, defaultMode, disableModal = false}) => {

    const {loading, isError, transformedData, currency, currencyCode, dataSource }
        = useContext(VPTGridContext);

    const [amountUnits, setAmountUnits] = useState(VPT_AMOUNT_UNITS.MILLIONS);
    const [timePeriod, setTimePeriod] = useState(VPT_TIME_PERIODS.YEAR);
    const [vptMode, setVptMode] = useState(defaultMode != null ? defaultMode : VPT_TABLE_MODES.MINI);
    const [columnDefs, setColumnDefs] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [scrollColumn, setScrollColumn] = useState(null);
    let metricRenderer;

    useEffect(() => {
        if (transformedData == null) {
            setRowData([]);
            setColumnDefs([]);
            setScrollColumn(null);
            return;
        }
        const newColumns = [{
            headerName: `${currencyCode}`,
            field: 'name',
            pinned: 'left',/*,
            cellRendererSelector: cellRendererSelector,*/
            sortable: false,
            cellStyle: params => {
                if (params.data.isSeparator) {
                    return {backgroundColor: '#B6D4F7'}
                } else if (params.node.level > 1)
                    return {paddingLeft: ((params.node.level - 1) * 20) + "px"};
                else
                    return {
                        textAlign: 'left'
                    }

            },
            width: onlyGridView ? 100 : 300
        }];
        Object.keys(transformedData?.periodsMetadata[timePeriod]).forEach((periodKey) => {
            const column = {
                headerName: `${convertDateFormat(transformedData?.periodsMetadata[timePeriod][periodKey].period_end_date)|| periodKey}`,
                field: periodKey,
                cellRenderer: metricRenderer,
                sortable: false,
                cellStyle: (params) => {
                    if (params.data.isSeparator) {
                        return {backgroundColor: '#B6D4F7'}
                    } else if (transformedData?.periodsMetadata[timePeriod][periodKey].timePeriodType === TIME_PERIOD_TYPE.ACTUAL) {
                        return {backgroundColor: 'white'}
                    } else {
                        return {backgroundColor: '#E4EFFC'}
                    }
                },
                width: 130
            };
            newColumns.push(column);
        });

        const newRows = [];
        if (vptMode === VPT_TABLE_MODES.MINI) {
            MiniVPTValuationMetrics.forEach(metricName => {
                const metric = transformedData?.valuationMetrics.find(object => object.seriesType === metricName);
                if (metric != null) {
                    const row = {
                        ...metric,
                        hierarchy: [metric.seriesType],
                    };
                    newRows.push(row);
                }
            });

            newRows.push({
                name: 'Main Metrics',
                key: 'Main Metrics',
                headerName: 'Main Metrics',
                hierarchy: ['Main Metrics'],
                isSeparator: true,
            });

            MiniVPTMainMetrics.forEach(metricName => {
                const metric = transformedData?.mainMetrics.find(object => object.seriesType === metricName);
                if (metric != null) {
                    const row = {
                        ...metric,
                        hierarchy: [metric.seriesType],
                    };
                    newRows.push(row);
                }
            });

            newRows.push({
                name: 'Financial Summary Metrics',
                key: 'Financial Summary Metrics',
                headerName: 'Financial Summary Metrics',
                hierarchy: ['Financial Summary Metrics'],
                isSeparator: true,
            });

            MiniVPTFinancialSummaryMetrics.forEach(metricName => {
                const metric = transformedData?.financialSummaryMetrics.find(object => object.seriesType === metricName);
                if (metric != null) {
                    const row = {
                        ...metric,
                        hierarchy: [metric.seriesType],
                    };
                    newRows.push(row);
                }

            });

        }


        if (vptMode === VPT_TABLE_MODES.FULL) {
            transformedData?.mainMetrics.forEach(metric => {
                const row = {
                    ...metric
                };
                newRows.push(row);
            })
        }

        if (vptMode !== VPT_TABLE_MODES.MINI) {

            newRows.push({
                name: 'Valuation Metrics',
                key: 'Valuation Metrics',
                headerName: 'Valuation Metrics',
                hierarchy: ['Valuation Metrics'],
                isSeparator: true,
            })
            transformedData?.valuationMetrics.forEach((metric) => {
                const row = {
                    ...metric,
                    hierarchy: [metric.seriesType],
                }
                newRows.push(row);
            });
        }


        if (vptMode === VPT_TABLE_MODES.FULL) {
            newRows.push({
                name: 'Financial Summary Metrics',
                key: 'Financial Summary Metrics',
                headerName: 'Financial Summary Metrics',
                hierarchy: ['Financial Summary Metrics'],
                isSeparator: true,
            });

            transformedData?.financialSummaryMetrics.forEach((metric) => {
                const row = {
                    ...metric,
                    hierarchy: [metric.seriesType],
                    /*
                    metric: metric.name,*/
                }
                newRows.push(row);
            })
        }
        setRowData(newRows);
        setColumnDefs(newColumns);

        // Find the scroll column, HACK alert:

        const scrollColumnId = timePeriod === VPT_TIME_PERIODS.QUARTER ? newColumns.find(column => column?.headerName?.includes('23')) :
            newColumns.find(column => column?.headerName?.includes('22'))
        setScrollColumn(scrollColumnId);

    }, [transformedData, amountUnits, timePeriod, vptMode]);

    useEffect(() => {
        if(isError){
            setRowData([]);
            setColumnDefs([]);
            setScrollColumn(null);
        }
    }, [isError])


    const valueRenderer = (value, type) => {
        let decimals = 2;
        switch (type) {
            case VPT_METRIC_UNITS.RATIO:
                return value == null ? null : `${value.toFixed(1)}`;
            case VPT_METRIC_UNITS.AMOUNT: {
                if (value == null) return null;
                let numberToUse;
                if (Math.abs(value) < 1000000) {
                    numberToUse = value;
                    decimals = 2;
                } else {
                    numberToUse = parseFloat((value / amountUnits).toFixed(0));
                    decimals = 0;
                }
                return formatNumber(numberToUse, decimals, true, currency);
            }
            case VPT_METRIC_UNITS.Number: {
                if (value == null || Number.isNaN(Number(value))) {
                    console.log('Breaking Value: ', value);
                    return null;
                }
                const numberToUse = parseFloat((value / amountUnits).toFixed(2));
                return formatNumber(numberToUse, decimals);
            }
            case VPT_METRIC_UNITS.PERCENT: {
                if (value == null || Number.isNaN(Number(value))) {
                    console.log('Breaking Value: ', value);
                    return null;
                }
                const numberToUse = parseFloat(value.toFixed(1));
                decimals = 1;
                return `${formatNumber(numberToUse, decimals)}%`;
            }
        }
        return value;
    };

    metricRenderer = (params) => {

        const hasSecondary = params.data.hasSecondary || params.value?.hasOwnProperty('secondary');

        if (!hasSecondary) return <div style={{textAlign: 'left'}}>{valueRenderer(params.value, params.data.unit)}</div>
        else {
            return (<div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>{valueRenderer(params.value.primary, params.data?.unit)}</div>
                <div style={{
                    fontStyle: 'italic',
                    fontSize: '12px',
                    color: params.value?.secondary < 0 ? 'red' : 'green'
                }}>
                    {valueRenderer(params.value?.secondary, params.data?.secondary?.unit)}
                </div>
            </div>);
        }

    };

    const periodsDropdownItems = [
        {
            key: '1',
            label: <Space direction={'horizontal'}
                          onClick={() => setTimePeriod(VPT_TIME_PERIODS.QUARTER)}><span>Quarters</span></Space>
        },
        {
            key: '2',
            label: <Space direction={'horizontal'} onClick={() => setTimePeriod(VPT_TIME_PERIODS.HALFYEAR)}><span>Half Years</span></Space>
        },
        {
            key: '3',
            label: <Space direction={'horizontal'}
                          onClick={() => setTimePeriod(VPT_TIME_PERIODS.YEAR)}><span>Annual</span></Space>
        }
    ];

    const amountUnitsDropdownItems = [
        {
            key: '1',
            label: <Space direction={'horizontal'}
                          onClick={() => setAmountUnits(VPT_AMOUNT_UNITS.THOUSANDS)}><span>Thousands</span></Space>
        },
        {
            key: '2',
            label: <Space direction={'horizontal'}
                          onClick={() => setAmountUnits(VPT_AMOUNT_UNITS.MILLIONS)}><span>Millions</span></Space>
        },
        {
            key: '3',
            label: <Space direction={'horizontal'}
                          onClick={() => setAmountUnits(VPT_AMOUNT_UNITS.BILLIONS)}><span>Billions</span></Space>
        }
    ]

    const vptModeDropdownItems = [
        {
            key: '1',
            label: <Space direction={'horizontal'}
                          onClick={() => setVptMode(VPT_TABLE_MODES.FULL)}><span>Full Table</span></Space>
        },
        {
            key: '2',
            label: <Space direction={'horizontal'} onClick={() => setVptMode(VPT_TABLE_MODES.VALUATION)}><span>Valuation Metrics</span></Space>
        },
        {
            key: '3',
            label: <Space direction={'horizontal'}
                          onClick={() => setVptMode(VPT_TABLE_MODES.MINI)}><span>Mini Mode</span></Space>
        },
    ]

    const [modalState, setModalState] = useState(false);
    const toggleModalState = () => setModalState(prev => !prev);

    return (
        <>
            {!onlyGridView && <div style={{display: 'flex', justifyContent: 'flex-end', gap: '20px'}}>
               { dataSource && <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{color: dataSource === 'VA' ? 'blue' : dataSource === 'BBG' ? 'green' : 'black'}}>
                        Source: {dataSource === 'VA' ? 'Visible Alpha' : dataSource === 'BBG' ? 'Bloomberg' : dataSource}
                    </span>
                </div>}
                <Dropdown
                    menu={{items: vptModeDropdownItems}}
                    arrow={true}
                >
                    <Button>
                        {`Table Mode: ${vptMode}`} <DownOutlined/>
                    </Button>
                </Dropdown>
                <Dropdown
                    menu={{items: periodsDropdownItems}}
                    arrow={true}
                >
                    <Button>
                        {`Time Periods: ${timePeriod}`} <DownOutlined/>
                    </Button>
                </Dropdown>

                <Dropdown
                    menu={{items: amountUnitsDropdownItems}}
                    arrow={true}
                >
                    <Button>
                        Amount Units: {convertUnitsToWords(amountUnits)} <DownOutlined/>
                    </Button>
                </Dropdown>
            </div>}

            <LoadingOverlay
                active={loading && !isError /*&& !(rowData.length > 0 && columnDefs.length > 0)*/}
                spinner={!isError}
                text={"Loading..."}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        height: onlyGridView ? gridHeight : 1000,
                        width: '100%'
                    })
                }}
            >
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className="ag-theme-alpine" style={{height: gridHeight, width: gridWidth}}>
                        {!isError && <AgGridReact
                            columnDefs={columnDefs}
                            rowData={rowData}
                            frameworkComponents={{
                                metricRenderer, DoubleStackRowHeaderRenderer, cellRendererSelector
                            }}
                            onModelUpdated={(params) => {
                                params?.api?.ensureColumnVisible(scrollColumn?.field, 'start')
                            }}
                            domLayout={onlyGridView ? 'normal' : 'autoHeight'}
                            treeData={true}
                            getRowHeight={(params) => params.node.data.isSeparator/* && onlyGridView*/ ? 3 : 29}
                            getDataPath={getDataPath}
                            headerHeight={20}
                            alwaysShowHorizontalScroll={false}
                            alwaysShowVerticalScroll={false}
                            onCellDoubleClicked={() => toggleModalState()}
                            scrollbarWidth={0}
                            autoGroupColumnDef={{
                                headerName: dataSource ? 'source:' + dataSource.toLowerCase() : '',
                                cellRendererParams: {
                                    suppressCount: true,
                                }
                            }}
                        />}
                        {isError && <h3>Failed to load VPT Data. Please refresh.</h3>}
                    </div>
                </div>
            </LoadingOverlay>
            {!disableModal && <Modal width={2500} open={modalState} onOk={toggleModalState} onCancel={toggleModalState}>
                <VPTGrid onlyGridView={false} defaultMode={VPT_TABLE_MODES.FULL} disableModal={true} gridWidth={2500}/>
            </Modal>}
        </>
    );
}
