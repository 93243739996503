// Build a system which gets passed: current full config, current metadata, and a predicate, which factors this, and applies it to all the series.
//Then when the ticker changes, all of these are then applied sequentially:

export const weeklyPredicate = (seriesMetadata, showWeeklySeries) =>
        showWeeklySeries ? (seriesMetadata.granularityTags.includes('WEEKLY') || seriesMetadata.seriesName.includes('wkly'))
            :
            !(seriesMetadata.granularityTags.includes('WEEKLY') || seriesMetadata.seriesName.includes('wkly')) ;


export const yipitPredicate = (seriesMetadata) => (seriesMetadata?.vendorTags?.includes("YIPIT") ||
    seriesMetadata?.seriesName?.toLowerCase().includes("yipit"));

export const placerPredicate = (seriesMetadata) => (seriesMetadata?.vendorTags?.includes("PLACER") ||
    seriesMetadata?.seriesName?.toLowerCase().includes("placer"))

export const appSeriesPredicate = (seriesMetadata) =>
    (seriesMetadata?.functionalTags?.includes("APP_DOWNLOAD") ||
        seriesMetadata?.functionalTags?.includes("APP_DAU")) &&
    !seriesMetadata?.functionalTags?.includes("FINANCIAL");

export const ccSeriesPredicate = (seriesMetadata) =>
    ((seriesMetadata?.functionalTags?.includes("CC") && seriesMetadata?.functionalTags?.includes("SALES") && seriesMetadata?.functionalTags?.includes("TOTAL")
        && !seriesMetadata?.functionalTags?.includes("FINANCIAL")) || seriesMetadata?.functionalTags?.includes("STUDIO_CC_INDEX"));

export const gtSeriesPredicate = (seriesMetadata) => (seriesMetadata?.functionalTags?.includes("WEB") && !seriesMetadata?.functionalTags?.includes("ALT_GT") &&
    seriesMetadata?.vendorTags?.includes("SERP_API") && !seriesMetadata?.functionalTags?.includes("FINANCIAL"));

export const similarWebPredicate = (seriesMetadata) => (seriesMetadata?.vendorTags?.includes("SIMILARWEB") &&
    !seriesMetadata?.functionalTags?.includes("FINANCIAL"))

export const segmentSeriesPredicate = (seriesMetadata) =>
    (seriesMetadata?.functionalTags?.includes("SEGMENT") && !seriesMetadata?.functionalTags?.includes("FINANCIAL"));

export const altGtPredicate = (seriesMetadata) => seriesMetadata?.functionalTags?.includes("WEB") && seriesMetadata?.functionalTags?.includes("ALT_GT") &&
    seriesMetadata?.vendorTags?.includes("SERP_API") && !seriesMetadata?.functionalTags?.includes("FINANCIAL");

export const oracleIndexPredicate = (seriesMetadata) => (seriesMetadata?.functionalTags?.includes("STUDIO_INDEX") && !seriesMetadata?.functionalTags?.includes("STUDIO_CC_INDEX"));

export const indexesPredicate = (seriesMetadata) => (seriesMetadata?.functionalTags?.includes("STUDIO_INDEX"));

// Weeklies for series which are not placer, app, gt && have the parent series active for it:
export const weekliesButtonPredicate = (seriesMetadata, currentChartConfig) => !appSeriesPredicate(seriesMetadata) && !gtSeriesPredicate(seriesMetadata) &&
    !altGtPredicate(seriesMetadata) && !placerPredicate(seriesMetadata) &&
    ((seriesMetadata.granularityTags.includes('WEEKLY') || seriesMetadata.seriesName.includes('wkly'))&&
        currentChartConfig[Object.keys(currentChartConfig).find(activeConfigSeries => activeConfigSeries !== seriesMetadata.seriesName && containsAllWords(activeConfigSeries, seriesMetadata.seriesName))]?.visible);

export const siPredicate = (seriesMetadata) => seriesMetadata?.functionalTags?.includes("SHORT_INTEREST");

export const kpiPredicate = (seriesMetadata) => seriesMetadata?.functionalTags?.includes("KPI") || seriesMetadata?.functionalTags?.includes("REVENUE");

export const findMatchingPredicate = (item, predicates) => {
    const matchingPredicate = predicates.find(predicate => predicate(item));
    return matchingPredicate ? matchingPredicate : null;
}

export function containsAllWords(candidateParentSeries, weeklySeries) {
    const wordsA = candidateParentSeries.split(/\s+/); // Split the string 'a' into an array of words using whitespace as the delimiter
    for (let word of wordsA) {
        if (!weeklySeries.includes(word)) {
            return false; // If any word from 'a' is not found in 'b', return false
        }
    }
    return true; // All words from 'a' are found in 'b', return true
}

export const inversePredicate = (predicate) => {
    return (...args) => !predicate(...args);
};

export const combinePredicates = (...predicates) => {
    return (item) => predicates.every((predicate) => predicate(item));
};