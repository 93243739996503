import {AgGridReact} from "@ag-grid-community/react";
import {Button, Col, Radio, Row, Switch, Tooltip} from "antd";
import {useEffect, useRef, useState} from "react";
import {getIntradayFactorLoadings, getRtStockAndIdioReturn} from "../../../api/data/PortfolioDataProvider";
import IntradayMarketFactorChart from "./IntradayMarketFactorChart";
import {columnDefs, transformationMap} from "./configuration/IntradayFactorsConfiguration";
import {FilterOutlined, InfoCircleOutlined} from "@ant-design/icons";
import '../dashboard/css/dashboard.css'
import LoadingOverlay from "react-loading-overlay";

import "./configuration/ag-grid-center.css";
import ExposureTable from "../overview/ExposureTable";
import PositionTable from "../overview/PositionTable";

const _ = require('lodash');

const applyTransformation = (rowData, type, source) => {
    let updatedRows = [];
    for (let transformKey of transformationMap.get(type)) {
        let value = null;
        let transformationLogic = transformKey[1];
        if (transformationLogic instanceof Function) {
            value = transformationLogic(source);
        } else if (typeof transformationLogic == 'string') {
            value = _.get(source, transformationLogic, null);
        }

        if (value !== null) {
            _.set(rowData, transformKey[0], value);
            if (updatedRows.length === 0) {
                updatedRows.push(rowData);
            }
        }
    }

    return updatedRows;
}

export default function IntradayFactors({timezoneOffset = 240}) {

    const [gridApi, setGridApi] = useState(null);
    const [returnsData] = useState([]);
    const [marketFactors, setMarketFactors] = useState(null); // New state for MarketFactorsHistorical data
    const [loading, setLoading] = useState(false);
    const [isLive, setIsLive] = useState(true);
    const [selectedTime, setSelectedTime] = useState(null);
    const [dataAsOfTime, setDataAsOfTime] = useState(null);
    const [factorDataTime, setFactorDataTime] = useState(null);
    const [filterPositions, setFilterPositions] = useState(true);
    const [frequency, setFrequency] = useState('300');

    const liveFactorUpdateRef = useRef(null);
    const liveReturnUpdateRef = useRef(null);

    const onFrequencyChange = (e) => {
        setFrequency(e.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getIntradayFactorLoadings();
                setMarketFactors(response.data);
                return response.data;
            } catch (err) {
                console.error("Failed to fetch data", err);
            }
        };

        fetchData()
            .then(r => {
                // Set the data initially for the header
                if (r && Array.isArray(r) && r[r.length - 1]) {
                    setFactorDataTime(Date.parse(r[r.length - 1]?.asOfTime));
                }
            }); // Call immediately to avoid waiting for the interval on component mount

        liveFactorUpdateRef.current = setInterval(fetchData, frequency * 1000);

        return () => {
            clearInterval(liveFactorUpdateRef.current);
        }
    }, [frequency]);

    const getReturnData = async (gridApi, abortController) => {
        if (gridApi) {
            setLoading(true);
            try {
                let filterTime = isLive ? null : selectedTime;
                console.log("Getting intraday returns at: ", filterTime);
                const stockAndIdioReturn = await getRtStockAndIdioReturn(filterPositions, filterTime, abortController);

                const data = stockAndIdioReturn.data;
                for (const returnObj of data) {
                    let tickerNode = gridApi.getRowNode(returnObj['tickerId']);
                    if (!tickerNode) {
                        let updatedData = applyTransformation({}, 'allData', returnObj);
                        gridApi.applyTransactionAsync({add: updatedData});
                    } else {
                        let updatedData = applyTransformation(tickerNode.data, 'allData', returnObj);
                        gridApi.applyTransactionAsync({update: updatedData});
                    }
                }

                setLoading(false);
            } catch (err) {
                console.error("Failed to fetch data", err);
            }
        }
    }

    useEffect(() => {
        let abortController = new AbortController();
        getReturnData(gridApi, abortController);

        if (isLive) {
            // Add a lag of 10 seconds
            setTimeout(() => {
            }, 10000);
            liveReturnUpdateRef.current = setInterval(() => getReturnData(gridApi, abortController), frequency * 1000);
        } else {
            clearInterval(liveReturnUpdateRef.current);
        }

        return () => {
            clearInterval(liveReturnUpdateRef.current);
            abortController.abort();
        }
    }, [frequency, isLive, selectedTime, filterPositions]);

    const handleTimeSelection = (selectedTime) => {
        if (typeof selectedTime === 'number') {
            console.log("selectedTime", selectedTime);
            setSelectedTime(selectedTime);
        } else {
            console.error("Invalid selectedTime type", selectedTime, typeof selectedTime);
        }
        setIsLive(false); // Turn off live updates
    };

    const _toggleFilterPositions = () => {
        setFilterPositions((prevState) => !prevState);
    }

    const handleLiveSwitchChange = (checked) => {
        setIsLive(checked);
        // If needed, add logic to pause live updates when switched off
    };

    const formatDate = (timestamp) => {
        if (!timestamp) timestamp = Date.now();
        const utcDate = new Date(timestamp);
        const options = {day: 'numeric', month: 'long', year: 'numeric', timeZone: 'UTC'};
        return utcDate.toLocaleDateString('en-US', options);
    };

    const formatTime = (timestamp) => {
        if (!timestamp) return '';
        const utcDate = new Date(timestamp);
        const options = {day: 'numeric', month: 'long', year: 'numeric'};
        return utcDate.toLocaleTimeString('en-US', options)
    };

    const accounts = ['U2319809','U615280','U10516305','U10525656','U3812243','U3732752','U2275992','U4168317','U4366920','U4093727','U3774454'];
    const accountIdMapExpanded = new Map();
    for (const accountId of accounts) {
        accountIdMapExpanded.set(accountId, {
            name: accountId,
            accountIds: [accountId]
        });
    }

    return (
        <div style={{marginTop: 50}}>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10,
                width: '100%'
            }}>
                    <span style={{fontWeight: 'bold', marginRight: 10, textAlign: 'center'}}>
                        {isLive ? <span>Live ({formatTime(dataAsOfTime)})</span> :
                            <span>{formatTime(selectedTime)}</span>}
                    </span>
                <Switch checked={isLive} onChange={handleLiveSwitchChange} checkedChildren='Live'
                        unCheckedChildren='Toggle Live Data'
                        style={{marginRight: 10}}/>
                {isLive && <Radio.Group value={frequency} onChange={onFrequencyChange} size="small" buttonStyle="solid">
                    <Radio.Button value="60">1 min</Radio.Button>
                    <Radio.Button value="300">5 min</Radio.Button>
                    <Radio.Button value="900">15 min</Radio.Button>
                </Radio.Group>}
                <span style={{margin: '0 20px'}}>|</span>
                <FilterOutlined style={{margin: '0 15px'}}/>
                <Button type={filterPositions ? "primary" : undefined}
                        onClick={_toggleFilterPositions}>Positions</Button>
                <Button type={!filterPositions ? "primary" : undefined} onClick={_toggleFilterPositions}
                        style={{marginLeft: 5}}>Full Coverage</Button>
            </div>
            <Row gutter={16} justify="center">
                <Col flex={1}>
                    <h4>Exposure</h4>
                    <ExposureTable intraday={true}/>
                </Col>
                <Col flex={1}>
                    <h4>Beta Adjusted Exposure</h4>
                    <ExposureTable intraday={true} betaAdjusted={true} setAsOfTime={setDataAsOfTime}/>
                </Col>
            </Row>
            <Row gutter={16} justify="center">
                <Col flex={1}>
                    <h4>Position List</h4>
                    <PositionTable intraday={true}/>
                </Col>
            </Row>
            <Row gutter={16} justify="center">
                <Col flex={1}>
                    <h4>Exposure</h4>
                    <ExposureTable intraday={true} accountIdMap={accountIdMapExpanded}/>
                </Col>
                <Col flex={1}>
                    <h4>Beta Adjusted Exposure</h4>
                    <ExposureTable intraday={true} betaAdjusted={true} accountIdMap={accountIdMapExpanded}/>
                </Col>
            </Row>
            {/* Display the formatted date above the chart */}
            <div style={{marginTop: '30px', textAlign: 'center', fontSize: '24px', position: 'relative'}}>
                <span><strong>{formatDate(factorDataTime)}</strong></span>
                <Tooltip title="Hover over the chart and press 's' to select any timestamp to see factor data for">
                    <InfoCircleOutlined style={{marginLeft: '10px', color: '#1890ff'}}/>
                </Tooltip>
            </div>

            <div style={{marginBottom: '20px'}}> {/* Adjusted for chart to be above and centered */}
                <IntradayMarketFactorChart marketFactors={marketFactors} isError={false}
                                           onSelectTime={handleTimeSelection} timezoneOffset={timezoneOffset}/>
            </div>
            {/* Adjusted for grid to be below and centered */}
            <LoadingOverlay active={loading} text={"Loading..."}>
                <div className="portfolio-dashboard ag-theme-alpine"
                     style={{height: '70vh', margin: '0px auto'}}>
                    <AgGridReact
                        className="ag-center"
                        onGridReady={(params) => {
                            setGridApi(params.api);
                            getReturnData(params.api);
                        }}
                        rowData={returnsData}
                        columnDefs={columnDefs}
                        getRowId={(params) => params.data.tickerId}
                    />
                </div>
            </LoadingOverlay>
        </div>
    )
}
