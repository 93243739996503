import React, { useState, useEffect } from 'react';
import { Layout, Input, Button, Card, Tag, Collapse, Typography, Table, Space, Spin, Alert } from 'antd';
import { computationService } from '../../api/Clients';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const DataTrace = ({ tickerId: propTickerId }) => {
    const [tickerId, setTickerId] = useState(propTickerId || '190');
    const [flowRuns, setFlowRuns] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await computationService.get(`/v1/monitoring/health/trace-pipelines/${tickerId}`);
            setFlowRuns(response.data);
        } catch (error) {
            console.error('Failed to fetch flow runs data:', error);
            setError('Failed to fetch data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [tickerId]);

    useEffect(() => {
        if (propTickerId) {
            setTickerId(propTickerId);
        }
    }, [propTickerId]);

    const handleTickerIdChange = (event) => {
        setTickerId(event.target.value);
    };

    const handleFetchClick = () => {
        fetchData();
    };

    const getStateTag = (state) => {
        const color = state === 'COMPLETED' ? 'green' : state === 'FAILED' ? 'red' : 'yellow';
        return <Tag color={color}>{state}</Tag>;
    };

    const formatTime = (timeString) => {
        const date = new Date(timeString);
        const formattedDate = date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        });
        return `${formattedDate} (${getTimeAgo(date)})`;
    };

    const getTimeAgo = (date) => {
        const now = new Date();
        const diffInSeconds = Math.floor((now - date) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);

        if (diffInDays > 0) {
            return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ${diffInHours % 24} hour${diffInHours % 24 !== 1 ? 's' : ''} ago`;
        } else if (diffInHours > 0) {
            return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
        } else if (diffInMinutes > 0) {
            return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
        } else {
            return 'just now';
        }
    };

    const renderParameters = (parameters) => (
        <Table
            dataSource={Object.entries(parameters).map(([key, value]) => ({ key, value: JSON.stringify(value) }))}
            columns={[
                { title: 'Key', dataIndex: 'key', key: 'key' },
                { title: 'Value', dataIndex: 'value', key: 'value' },
            ]}
            pagination={false}
            size="small"
            style={{ marginBottom: '16px' }}
        />
    );

    const renderArtifacts = (artifacts) => (
        <>
            {artifacts.map((artifact, index) => (
                <div key={index} style={{ marginBottom: '16px' }}>
                    <Text strong>Key: </Text><Text>{artifact.key}</Text> <Text strong>Created: </Text><Text>{artifact.created}</Text>
                    <Table
                        dataSource={[JSON.parse(artifact.data)]}
                        columns={Object.keys(JSON.parse(artifact.data)).map(key => ({
                            title: key,
                            dataIndex: key,
                            key: key,
                            render: (text) => (
                                <Text
                                    ellipsis={{ 
                                        tooltip: JSON.stringify(text),
                                        rows: 2
                                    }}
                                    style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
                                >
                                    {JSON.stringify(text)}
                                </Text>
                            )
                        }))}
                        pagination={false}
                        size="small"
                        style={{ marginTop: '8px' }}
                    />
                </div>
            ))}
        </>
    );

    return (
        <Layout>
            {!propTickerId && (
                <div style={{ padding: '24px', marginBottom: '24px' }}>
                    <Input
                        value={tickerId}
                        onChange={handleTickerIdChange}
                        placeholder="Enter Ticker ID"
                        style={{ width: 300, marginRight: '16px' }}
                    />
                    <Button onClick={handleFetchClick} type="primary" size="large" loading={loading}>
                        Fetch Flow Runs
                    </Button>
                </div>
            )}

            {loading && (
                <div style={{ textAlign: 'center', padding: '24px' }}>
                    <Spin size="large" />
                    <Text style={{ display: 'block', marginTop: '16px' }}>Loading data...</Text>
                </div>
            )}

            {error && (
                <Alert
                    message="Error"
                    description={error}
                    type="error"
                    showIcon
                    style={{ margin: '24px' }}
                />
            )}

            {!loading && !error && Object.keys(flowRuns).length === 0 && (
                <Alert
                    message="No Data Found"
                    description={`No data available for ticker ID: ${tickerId}`}
                    type="info"
                    showIcon
                    style={{ margin: '24px' }}
                />
            )}

            {!loading && !error && Object.keys(flowRuns).length > 0 && (
                Object.entries(flowRuns).map(([flowId, runs]) => (
                    <Card key={flowId} style={{ marginBottom: '32px' }}>
                        <Title level={3} style={{ marginBottom: '16px' }}>{flowId}</Title>
                        <Collapse defaultActiveKey={runs.map(run => run.id)}>
                            {runs.map((run) => (
                                <Panel 
                                    key={run.id} 
                                    header={
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Space>
                                                <Text>runid: {run.id}</Text><Text strong style={{ fontSize: '14px' }}>{run.name}</Text>
                                                {getStateTag(run.state_type)}
                                            </Space>
                                            <Text style={{ fontSize: '12px' }}>
                                                <strong>Start:</strong> {formatTime(run.start_time)} | <strong>End:</strong> {formatTime(run.end_time)}
                                            </Text>
                                        </div>
                                    }
                                >
                                    <Text strong style={{ display: 'block', marginBottom: '8px', fontSize: '16px' }}>Parameters:</Text>
                                    {renderParameters(run.parameters)}
                                    <Text strong style={{ display: 'block', marginBottom: '8px', marginTop: '16px', fontSize: '16px' }}>Artifacts:</Text>
                                    {renderArtifacts(run.artifacts)}
                                </Panel>
                            ))}
                        </Collapse>
                    </Card>
                ))
            )}
        </Layout>
    );
};

export default DataTrace;