import {dwService, fmdService, macroService, pfService, pipelineService} from "../Clients";
import {apiKeyInfo} from "../Config";


const doOrScheduleGetCall = (ticker, apiUrl, apiOptions, service, scheduler, priority) => {
    const getCall = () => service.get(apiUrl, apiOptions);
    if (scheduler === null) return getCall();
    return scheduler.scheduleApiCall(getCall, ticker, priority);
}

export const getProjectionsChartData = (tickerId, brandId, queryType, algorithm, seriesName, controller, scheduler = null, priority = 1) => {
    const apiUrl = `/dashboard/ticker/uc?tickerId=${tickerId}&queryType=${queryType}&ucAlgorithm=${algorithm}&seriesName=${seriesName}`;
    const apiOptions = {
        signal: controller?.signal
    };
    return doOrScheduleGetCall(tickerId,  apiUrl, apiOptions, dwService, scheduler, priority);
}

export let getData = (ticker, queryType, controller, scheduler = null, priority = 1, peerIndices = false) => {
    let apiUrl = `/dashboard/ticker/query?ticker=${ticker}&queryType=${queryType}`;
    if (peerIndices) {
        apiUrl += `&calculatePeerIndexes=${peerIndices}`;
    }
    const apiOptions = {signal: controller?.signal};
    return doOrScheduleGetCall(ticker, apiUrl, apiOptions, dwService, scheduler, priority);
}


export let getAxisCombinedData = (ticker, queryType, controller, scheduler = null, priority = 1, peerIndices = false) => {

    let apiUrl = `/dashboard/ticker/query?ticker=${ticker}&queryType=${queryType}&combineAxis=true`;
    if (peerIndices) {
        apiUrl += `&calculatePeerIndexes=${peerIndices}`;
    }
    const apiOptions = {signal: controller?.signal};
    return doOrScheduleGetCall(ticker, apiUrl, apiOptions, dwService, scheduler, priority);

}

export let getDataForBrand = (ticker, brandId, queryType, controller, scheduler = null, priority = 1, peerIndices = false) => {
    let apiUrl = `/dashboard/brand/query?ticker=${ticker}&queryType=${queryType}&brandId=${brandId}`;
    if (peerIndices) {
        apiUrl += `&calculatePeerIndexes=${peerIndices}`;
    }
    const apiOptions = {signal: controller?.signal};
    return doOrScheduleGetCall(ticker, apiUrl, apiOptions, dwService, scheduler, priority);
}

export let getAxisCombinedDataForBrand = (ticker, brandId, queryType, controller, scheduler = null, priority = 1, peerIndices = false) => {
    let apiUrl = `/dashboard/brand/query?ticker=${ticker}&queryType=${queryType}&brandId=${brandId}&combineAxis=true`;
    if (peerIndices) {
        apiUrl += `&calculatePeerIndexes=${peerIndices}`;
    }
    const apiOptions = {signal: controller?.signal};
    return doOrScheduleGetCall(ticker, apiUrl, apiOptions, dwService, scheduler, priority);

}
//Todo: Refactor all the function below to reuse the same code:

export let getTickers = (scheduler = null) => {
    const apiUrl = "/dashboard/ticker/list";

    const apiCall = () => dwService.get(apiUrl);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, null, 1);
}

export let getBrands = (tickerId, scheduler = null) => {
    const apiUrl = `/dashboard/brand/list?tickerId=${tickerId}`;

    const apiCall = () => dwService.get(apiUrl);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, tickerId, 1);
}
export let getStockGrades = (ticker, scheduler = null) => {
    const apiUrl = `/api/v3/grade/${ticker}?limit=10&apikey=${apiKeyInfo.fin_mkt_api_key}`;

    const apiCall = () => fmdService.get(apiUrl);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, ticker, 1);
}

export let getInsiderTransactions = (ticker, scheduler = null) => {
    const apiUrl = `/api/v4/insider-trading?symbol=${ticker}&page=0&apikey=${apiKeyInfo.fin_mkt_api_key}`;

    const apiCall = () => fmdService.get(apiUrl);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, ticker, 1);
}

export const getWtdStacks = (tickerId, brandId, ccSeries, yearFactor, controller) => {
    const apiUrl = brandId == null ? `/dashboard/ticker/stack/wtd?tickerId=${tickerId}&ccSeries=${ccSeries}&yearFactor=${yearFactor}` : `/dashboard/ticker/stack/wtd?tickerId=${tickerId}&ccSeries=${ccSeries}&yearFactor=${yearFactor}&brandId=${brandId}`;
    const apiOptions = {signal: controller?.signal};
    // No scheduler needed for now,
    return dwService.get(apiUrl, apiOptions);
}

export let getAuthStatus = (apiKey, scheduler = null) => {
    const apiUrl = `/dashboard/auth/check?apiKey=${apiKey}`;

    const apiCall = () => dwService.get(apiUrl);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, null, 1);
}

export let getPreferences = (ticker, brandId, scheduler = null) => {
    let apiUrl = `/dashboard/preference?ticker=${ticker}`;
    if (brandId != null) {
        apiUrl += `&brandId=${brandId}`;
    }

    const apiCall = () => dwService.get(apiUrl);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, ticker, 1);
}

export let getCorrelationData = (ticker, selectedDepVariable, controller, scheduler = null) => {
    const apiUrl = `/dashboard/ticker/correlation/${ticker}?dependentVariable=${encodeURI(selectedDepVariable)}`;
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => dwService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, ticker, 1);
}


export let getLiquidityMacro = () => {
    return macroService.get("/sf-f7ddba77-a5d8-4b40-8877-7d2196dd429a?format=raw")
}

export let getPublicDebt = () => {
    return macroService.get("/sf-f334c87c-5d08-47d6-8793-15b4600bf43b?format=raw")
}

export let getSpxFairValue = () => {
    return macroService.get("/sf-7a52acf1-2cdd-476c-8560-430e20c57070?format=raw")
}

// Clickouts
export let getClickoutUrl = (tickerId, brandId, clickOutType, controller, scheduler = null) => {
    let apiUrl = `/dashboard/clickout?tickerId=${tickerId}&clickOutType=${clickOutType}`;
    if (brandId != null) {
        apiUrl += `&brandId=${brandId}`;
    }
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => dwService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, tickerId, 1);
}

// Data Availability
export let getDataLatency = (tickerId, brandId, scheduler = null) => {
    let apiUrl = `/dashboard/data/availability?tickerId=${tickerId}`;
    if (brandId != null) {
        apiUrl += `&brandId=${brandId}`;
    }

    const apiCall = () => dwService.get(apiUrl);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, tickerId, 1);
}

// Dynamic Alt Gt Stack Chart
export let getAltGtTerms = (ticker, controller, scheduler = null) => {
    const apiUrl = `/dashboard/data/listAltGtTerms?ticker=${ticker}`;
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => dwService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, ticker, 1);
}
export let getDynAltGtData = (ticker, queryType, queryTerm, location, controller, scheduler = null) => {
    const apiUrl = `/dashboard/ticker/query/altGt?ticker=${ticker}&queryType=${queryType}&queryTerm=${encodeURIComponent(queryTerm)}&location=${location}`;
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => dwService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, ticker, 1);
}

// Macro Signals Stack Query
export let getMacroResults = (queryType, controller, scheduler = null) => {
    const apiUrl = `/macro/query?queryType=${queryType}`;
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => dwService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, null, 1);
}

export let getMacroSectorResults = (sectorRegex, queryType, controller, scheduler = null) => {
    let apiUrl = undefined;
    if (!sectorRegex) {
        apiUrl = `/dashboard/sector/query?queryType=${queryType}&`
    } else {
        apiUrl = `/dashboard/sector/query/regex?queryType=${queryType}&parentSectorRegex=${sectorRegex}.*`;
    }
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => dwService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, null, 1);
}

// Macro Signals Stack Query
export let getIndexWeights = (ticker, indexType, controller, scheduler = null) => {
    let apiUrl = `/dashboard/index/${ticker}`;
    if (indexType) {
        apiUrl += `?indexType=${indexType}`;
    }
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => dwService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, ticker, 1);
}

// Get Competitor List
export let getCompetitorList = (tickerId, controller) => {
    let url = "/taxonomy/peers?tickerId=" + tickerId;
    return dwService.get(url, {
        signal: controller?.signal
    })
}

// Market Factors Data Query
export let getMarketFactorsData = (fromDate, toDate, controller, scheduler = null) => {
    const apiUrl = `/factor/all?fromDate=${fromDate}&toDate=${toDate}`;
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => pfService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, null, 1);
}

// Watch Lists
export let getWatchLists = (controller, scheduler = null) => {
    const apiUrl = "/watchlist/names";
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => dwService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, null, 1);
}

export let getWatchListItems = (listName, controller, scheduler = null) => {
    const apiUrl = `/watchlist?listName=${encodeURIComponent(listName)}`;
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => dwService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, null, 1);
}

export let getTickName = (tickerId, scheduler = null) => {
    const apiUrl = `/watchlist/tick?tickerId=${tickerId}`;

    const apiCall = () => dwService.get(apiUrl);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, tickerId, 1);
}
export let getTickInfo = (tickerIds, scheduler = null) => {
    const apiUrl = "/watchlist/info";

    const apiCall = () => dwService.post(apiUrl, tickerIds);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, tickerIds, 1);
}

export let addToLongWL = (tickerId) => {
    return dwService.post("/watchlist", {
        listName: "LONG_WL",
        tickerIds: [tickerId]
    });
}

export let removeFromLongWL = (tickerId) => {
    return dwService.delete("/watchlist?listName=LONG_WL&tickerId=" + tickerId);
}

export let addToShortWL = (tickerId) => {
    return dwService.post("/watchlist", {
        listName: "SHORT_WL",
        tickerIds: [tickerId]
    });
}

export let removeFromShortWL = (tickerId) => {
    return dwService.delete("/watchlist?listName=SHORT_WL&tickerId=" + tickerId);
}
export let addToAWL = (tickerId) => {
    return dwService.post("/watchlist", {
        listName: "ADD_WL",
        tickerIds: [tickerId]
    });
}

export let removeFromAWL = (tickerId) => {
    return dwService.delete("/watchlist?listName=ADD_WL&tickerId=" + tickerId);
}
export let addToRWL = (tickerId) => {
    return dwService.post("/watchlist", {
        listName: "REDUCE_WL",
        tickerIds: [tickerId]
    });
}

export let removeFromRWL = (tickerId) => {
    return dwService.delete("/watchlist?listName=REDUCE_WL&tickerId=" + tickerId);
}

export let addToWDDWL = (tickerId) => {
    return dwService.post("/watchlist", {
        listName: "WDD_WL",
        tickerIds: [tickerId]
    });
}

export let removeFromWDDWL = (tickerId) => {
    return dwService.delete("/watchlist?listName=WDD_WL&tickerId=" + tickerId);
}

export let getActiveFlag = (tickerId, scheduler = null) => {
    const apiUrl = `/watchlist/current?tickerId=${tickerId}`;

    const apiCall = () => dwService.get(apiUrl);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, tickerId, 1);
}

export let saveNotes = (tickerId, note, scheduler = null) => {
    const apiUrl = `/watchlist/note?tickerId=${tickerId}`;
    const bodyData = {note: note}

    const apiCall = () => dwService.post(apiUrl, bodyData);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, tickerId, 1);
}

// Sw Dynamic Stack Chart for Sub URL's
export let getAvlUrls = (tickerId, mode) => {
    return dwService.get("/similarweb/url/list?tickerId=" + tickerId + "&mode=" + mode);
}

export let getSwVisitsStack = (searchId, location, mode, controller, scheduler = null) => {
    const apiUrl = `/similarweb/query/url?similarWebSearchId=${searchId}&location=${location}&mode=${mode}`;
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => dwService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, searchId, 1);
}

export let triggerPipeline = (tickerId) => {
    return pipelineService.post("/pipeline/run", {
        tickerIds: tickerId.toString()
    });
}

export let getPipelineStatus = (tickerId) => {
    return pipelineService.post("/pipeline/status", {
        tickerId: tickerId.toString()
    });
}

// Portfolio Data Provider
export let getPositionInfo = (tickerId, controller) => {
    return pfService.get("/portfolio/position/ticker?tickerId=" + tickerId, {
        signal: controller?.signal
    });
}


// Extra week indicator on top left of Nav bar
export let getExtraWeekIndicator = (ticker, scheduler = null) => {
    const apiUrl = `/dashboard/has-extra-week/${ticker}`;

    const apiCall = () => dwService.get(apiUrl);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, ticker, 1);
}

export let saveLastExtraWeek = (ticker, date) => {
    let nullOrDate = date === '' ? null : date;

    return dwService.post("/dashboard/has-extra-week/" + ticker, {
        'lastExtraWeek': nullOrDate
    });
}

// Short Interest Data Provider
export let getShortInterestInfo = (tickerId, controller, scheduler = null) => {
    const apiUrl = `/findata/shortinterest?tickerId=${tickerId}`;
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => dwService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, tickerId, 1);
}

// Historical stock price data provider:
export let getHistoricalStockData = (tickerId, controller, scheduler = null) => {
    const apiUrl = `/findata/historical-stock-price/${tickerId}`;
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => pfService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, tickerId, 1);
}

// Intraday Overview data (stock exchange, currency, active hours etc.)
export let getIntradayOverview = (tickerId, controller, scheduler = null) => {
    const apiUrl = `/overview/stock/${tickerId}`;
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => pfService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, tickerId, 1);
}

export let getIntradayPrices = (tickerId, controller, scheduler = null) => {
    const apiUrl = `/findata/intraday-stock-price/${tickerId}`;
    const apiOptions = {signal: controller?.signal};

    const apiCall = () => pfService.get(apiUrl, apiOptions);

    if (scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, tickerId, 1);
}

// Events Data Provider
export let getNextEarningsDate = (tickerId, controller) => {
    return dwService.get("/event/earnings/expected?tickerId=" + tickerId, {
        signal: controller?.signal
    });
}

// Authenticate User
export let loginUser = (username, password) => {
    return dwService.post("/api/users/login", {
        email: username,
        password: password
    });
}

// Audit Basic User Activity
export let auditUser = (user, section, ticker) => {
    return dwService.post("/api/users/audit", {
        user: user,
        section: section,
        ticker: ticker
    });
}

export let getUserPortfolioAuthStatus = (user, section, ticker) => {
    return dwService.post("/api/users/auth/portfolio", {
        user: user,
        section: section,
        ticker: ticker
    });
}

export let getVisibleAlphaClickoutUrl = (tickerId) => {
    return dwService.get(`/dashboard/clickout/visiblealpha?tickerId=${tickerId}`);
}

export const getEarningsEventsForTicker = (tickerId, scheduler = null) => {
    const apiUrl = `/event/earnings/${tickerId}`;

    const apiCall = () => dwService.get(apiUrl);

    if(scheduler === null) {
        return apiCall();
    }

    return scheduler.scheduleApiCall(apiCall, tickerId, 1);
}
